/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { Primary, Secondary } from 'aether-marketing';
import InterlinkingData from '../../../../InterlinkingData.json';

// Refer to InterlinkingData.json for prop keys

// !important needed to override Aether's default styles
const ContentWrapper = styled.div`
  border: ${(css) => (css.border ? `${css.border} !important` : 'none')};
  background: ${(css) =>
    css.background ? `${css.background} !important` : 'transparent'};
  padding: 20px 20px;
  border-radius: 20px;

  @media screen and (max-width: 576px) {
    padding: 20px;
    border-radius: 20px;
  }
`;

const Button = styled.button`
  ${(props) =>
    props.buttonType === 'secondary'
      ? Secondary.componentStyle.rules
      : Primary.componentStyle.rules};
  background: ${(css) =>
    css.background ? `${css.background} !important` : 'transparent'};
  border: ${(css) =>
    css.border ? `${css.border} !important` : '1px solid #212121 !important'};
  display: inline-block;
  color: #212121 !important;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  padding: 9px 12px;
  margin: 0;
  margin-bottom: 0 !important;
  transition: all 0.2s ease-in-out 0s;
  text-align: center;
  border: none;
  outline: none;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 4px 12px !important;
    margin-top: 10px;
  }

  :hover,
  :active,
  :focus {
    background: #212121 !important;
    border: 1px solid #212121 !important;
    color: #ffffff !important;
    opacity: 1;
    outline: none;
    text-decoration: none;
  }
`;
function InterlinkCTA(data) {
  const { platform, key } = data;
  const category =
    InterlinkingData[platform.charAt(0).toLowerCase() + platform.slice(1)] ||
    {};
  const cta = category.key[key] || {};

  return (
    <section>
      <div className="container">
        <ContentWrapper css={category.css}>
          <div className="row">
            <div className="col-12 col-sm-2 col-md-3 d-flex align-items-center justify-content-center mb-1 mb-sm-0">
              <img
                className="img-fluid d-md-block d-none"
                src={category.img.web}
                alt={`${category.img.alt} Illustration.`}
              />
              <img
                className="img-fluid d-md-none d-block"
                src={category.img.mobile}
                alt={`${category.img.alt} Icon.`}
              />
            </div>
            <div className="col-12 col-sm-10  col-md-9 d-block text-center text-sm-left d-sm-flex align-items-center justify-content-between mt-1 mt-sm-0">
              <div className="p-1">
                <p className="h3 d-md-block d-none mb-0" style={{ margin: 0 }}>
                  {cta.title}
                </p>
                <p
                  className="font-weight-bold d-block d-md-none"
                  style={{ margin: 0 }}
                >
                  {cta.title}
                </p>
              </div>
              <Button
                as="a"
                buttonType="secondary"
                href={`${cta.link + category.utm}`}
                style={{ whiteSpace: 'nowrap' }}
              >
                {cta.button}
              </Button>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </section>
  );
}

export default InterlinkCTA;
