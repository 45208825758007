/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */

import {
  HeroStacked,
  BackLinkSection,
  CallOut,
  SideXSide,
  TextSection,
  Feature,
  VideoComponent,
  Divider,
  Hero
} from 'aether-marketing';
import TextImageSideXSide from './Shared/sota-vertical/TextImageSideXSide';
import hero from './Shared/heroes/Hero';
import LegalMarkdown from './Shared/LegalMarkdown';
import ImageXText from './Shared/api-glossary/ImageXText';
import metricTwo from './Shared/metricTwo';
import amplitude from './Shared/amplitude';
import amplitudeExample from './Shared/amplitudeExample';
import footer from './Shared/footer';
import pmCollectionGrid from './Shared/pmCollectionGrid';
import generalNewsGrid from './Shared/generalNewsGrid';
import featuredItemHorizontal from './Shared/featuredItemHorizontal';
import heroCentered from './Shared/heroes/heroCentered';
import featureV6toggle from './Shared/featureV6toggle';
import featureWithSpotV6 from './Shared/featureWithSpotV6';
import downloadsHero from './Shared/downloads/downloadsHero';
import slider from './Shared/slider';
import leftTitleWithText from './Shared/leftTitleWithText';
import Job from './Shared/Job';
import joblistings from './Shared/joblistings';
import centeredImage from './Shared/centeredImage';
import pricingSection from './Shared/pricing/pricingCardSection';
import pricingTable from './Shared/pricing/pricingTable';
import addOnsPricingCards from './Shared/pricing/addOnsPricingCards';
import faqSection from './Shared/faqSection';
import subprocessorTable from './Shared/subprocessorTable';
import canaryCards from './Shared/downloads-canary/canaryCards';
import blogPostCardsDynamic from './Shared/blogPostCardsDynamic';
import blogPostCardsStatic from './Shared/blogPostCardsStatic';
import table from './Shared/table';
import sideBar from './Shared/sidebars/sideBar';
import heroWithModalAndButton from './Shared/heroes/heroWithModalAndButton';
import webinarRecapHero from './Shared/pages/webinars/webinarRecapHero';
import stickySideBar from './Shared/sidebars/stickySideBar';
import headerRow from './Shared/texts/headerRow';
import textRow from './Shared/texts/textRow';
import stickyModalNav from './Shared/navs-mobile/sticky-modal-nav/stickyModalNav';
import headerRowStickyShrink from './Shared/texts/headerRowStickyShrink';
import organizationSchema from '../utils/organizationSchema';
import localBusinessSchema from '../utils/localBusinessSchema';
import cardBasicGrid from './Shared/v5_cards/cardBasicGrid';
import externalScriptTag from '../utils/externalScriptTag';
import mapVisualizationSection from './Shared/mapVisualizationSection';
import FilterSection from './Shared/filters/FilterSection';
import caseStudyThreeAcrossV6 from './Shared/case-studies-new/pages/caseStudyThreeAcrossV6';
/* eslint-disable import/no-cycle */
import heroTitlesStacked from './Shared/v5_heros/heroTitlesStacked';
import cardGridFilterByDate from './Shared/v5_cards/cardGridFilterByDate';
import overheadImageWithIconSection from './Shared/v5_page-sections/overheadImageWithIconSection';
import eventsHero from './Shared/heroes/eventsHero';
import logoWallSectionV6 from './Shared/cta/logoWallSectionV6';
import floatingHeaderSectionV6 from './Shared/floatingHeaderSectionV6';
import listRichV6 from './Shared/listRichV6';
import sectionCtaNumberedV6 from './Shared/sectionCtaNumberedV6';
import splitVideoSection from './Shared/splitVideoSection';
import HeroWithLargeImageV6 from './Shared/heroes/HeroWithLargeImageV6';
import HeroStackedVideo from './Shared/HeroStackedVideo';
import sideBySideFormV6 from './Shared/sideBySideFormV6';
import alertV6 from './Shared/alertV6';
import AnnouncementSection from './Shared/v6_announcement/AnnouncementSection';
import sideBySideDataService from './Shared/sideBySideDataService';
import caseStudySectionV6 from './Shared/caseStudySectionV6';
import RunInPostmanButton from './Shared/RunInPostmanButton';
import sectionQuote from './Shared/sectionQuote';
import cardBasicBlogPost from './Shared/v5_cards/cardBasicBlogPost';
import sideBySideMediaTextV6 from './Shared/sideBySideMediaTextV6';
import stickyBar from './Shared/stickyBar';
import ExampleForm from './Shared/forms/layout/ExampleForm';
import cardToggle from './Shared/cardToggle';
import PrevNextLinks from './Shared/PrevandNextLinks';
import SliderV6 from './Shared/v6_sliders/SliderV6';
import SignupInputBox from './Shared/SignupInputBox';
import eventDynamic from './Shared/eventDynamic';
import MonthAnnualToggle from './Shared/MonthAnnualToggle';
import cardIconChecklistSection from './Shared/pricing/cardIconChecklistSection';
import sideBySideSpecial from './Shared/sideBySideSpecial';
import SideXSidePricing from './Shared/pricing/SideXSidePricing';
import stickyBarStart from './Shared/stickyBarStart';
import GoogleEntitySchema from '../utils/GoogleEntitySchema';
import LeftNav from './Shared/sidebars/LeftNav';
import SideBySideLottie from './Shared/SideBySideLottie';
import generateVideoSchema from '../utils/generateVideoSchema';
import HeroFullWidth from './Shared/heroes/heroFullWidth';
import chart2023SideBySide from './Shared/charts/chart2023SideBySide';
import PostconHero from './Shared/PostconHero';
import ImageGrid from './Shared/ImageGrid';
import PostconSideBySide from './Shared/PostconSideBySide';
import cardPostbotSection from './Shared/pricing/cardPostbotSection';
import PreFooterSideBySideLottie from './Shared/PreFooterSideBySideLottie';
import PostconFooter from './Shared/PostconFooter';
import PostconCallOut from './Shared/PostconCallOut';
import PostconCards from './Shared/PostconCards';
import pixelScript from '../utils/pixelScript';
import PostconNavBar from './PostconNavBar';
import sideBySideFormHero from './Shared/sideBySideFormHero';
import PostconAgenda from './Shared/PostconAgenda';
import PricingTabsSection from './Shared/pricing/pricingTabsSection';
import PricingAddonsTable from './Shared/pricing/pricingAddOnsTable';
import backLinkSectionDarkBackground from './Shared/elements/backLinkSectionDarkBackground';
import TabsSection from './Shared/tabs/tabsSection';
import TableColumns from './Shared/tables/tableColumns';
import CalloutDark from './Shared/cta/CalloutDark';
import LogoWallSideBySide from './Shared/cta/LogoWallSideBySide';
import SideBySideTabs from './Shared/sideBySideTabs';
import TopProductNavBar from './TopProductNavBar';
import Hotjar from '../utils/Hotjar';
import CustomTable from './Shared/tables/CustomTable';
import SideBySideQuote from './Shared/SideBySideQuote';
import LaunchPadCTA from './Shared/cta/LaunchPadCTA';
import InterlinkCTA from './Shared/cta/InterlinkCTA';
import ComparisonTable from './Shared/tables/ComparisonTable';

export default {
  SideBySideQuote,
  TextImageSideXSide,
  ImageXText,
  Hero,
  LegalMarkdown,
  cardToggle,
  HeroStacked,
  BackLinkSection,
  CallOut,
  SideXSide,
  TextSection,
  Feature,
  VideoComponent,
  Divider,
  PrevNextLinks,
  heroTitlesStacked,
  hero,
  metricTwo,
  amplitude,
  amplitudeExample,
  eventDynamic,
  footer,
  pmCollectionGrid,
  generalNewsGrid,
  featuredItemHorizontal,
  heroCentered,
  featureV6toggle,
  featureWithSpotV6,
  slider,
  downloadsHero,
  leftTitleWithText,
  Job,
  joblistings,
  centeredImage,
  pricingSection,
  pricingTable,
  addOnsPricingCards,
  faqSection,
  subprocessorTable,
  canaryCards,
  blogPostCardsDynamic,
  blogPostCardsStatic,
  table,
  sideBar,
  heroWithModalAndButton,
  webinarRecapHero,
  stickySideBar,
  headerRow,
  textRow,
  stickyModalNav,
  headerRowStickyShrink,
  organizationSchema,
  cardBasicGrid,
  localBusinessSchema,
  externalScriptTag,
  mapVisualizationSection,
  FilterSection,
  caseStudyThreeAcrossV6,
  cardGridFilterByDate,
  overheadImageWithIconSection,
  eventsHero,
  logoWallSectionV6,
  floatingHeaderSectionV6,
  listRichV6,
  sectionCtaNumberedV6,
  splitVideoSection,
  HeroWithLargeImageV6,
  HeroStackedVideo,
  sideBySideFormV6,
  alertV6,
  AnnouncementSection,
  sideBySideDataService,
  caseStudySectionV6,
  RunInPostmanButton,
  sectionQuote,
  cardBasicBlogPost,
  sideBySideMediaTextV6,
  stickyBar,
  ExampleForm,
  SliderV6,
  SignupInputBox,
  LeftNav,
  MonthAnnualToggle,
  cardIconChecklistSection,
  sideBySideSpecial,
  SideXSidePricing,
  stickyBarStart,
  GoogleEntitySchema,
  SideBySideLottie,
  generateVideoSchema,
  HeroFullWidth,
  chart2023SideBySide,
  PostconHero,
  ImageGrid,
  PostconSideBySide,
  cardPostbotSection,
  PreFooterSideBySideLottie,
  PostconFooter,
  PostconCallOut,
  PostconCards,
  pixelScript,
  PostconNavBar,
  sideBySideFormHero,
  PostconAgenda,
  PricingTabsSection,
  PricingAddonsTable,
  backLinkSectionDarkBackground,
  TabsSection,
  TableColumns,
  CalloutDark,
  LogoWallSideBySide,
  SideBySideTabs,
  TopProductNavBar,
  Hotjar,
  CustomTable,
  LaunchPadCTA,
  InterlinkCTA,
  ComparisonTable
};
