/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import {
  Divider,
  Paragraph,
  BaseButton,
  Secondary,
  BaseLink,
  BaseLinkStyles
} from 'aether-marketing';
import SVGComponent from '../svg/SVGComponent';
import ModalComponent from '../v5_modal-overlays/ModalComponent';
import SignUpPostmanEmail from '../SignUpPostmanEmailField';
import LottiAnimationContainer from '../LottiAnimationContainer';

// eslint-disable-next-line import/no-extraneous-dependencies
const pmUuid = require('@postman/uuid');

const Section = styled.section`
  background-color: ${(props) => props.theme.colors.white};
  padding-top: ${(props) => props.paddingTop || `60px`};
  padding-bottom: ${(props) => props.paddingBottom || `60px`};
  .video-btn {
    ${Secondary.componentStyle.rules}
  }
  .video-btn-white {
    color: white !important;
    border: 1px solid white;
    border-radius: ${(props) => props.theme.borderRadius.small};
  }

  @media screen and (max-width: 1099px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .reduce-img {
    height: 100%;
    @media (min-width: 768px) {
      height: 375px;
    }
  }
  .salesCta {
    color: #3d99ff;
    :hover {
      text-decoration: none;
      border-bottom: 1px solid #3d99ff;
    }
  }
`;
const ParentTitleTemp = styled.p`
  color: ${(props) => props.theme.colors.grey_90};
  font-weight: 600;
`;

const ParentTitleText = styled.p`
  color: ${(props) => props.theme.colors.orange_40};
  text-transform: uppercase;
  font-weight: 600;
`;

const ParentSubtitleText = styled.p`
  color: ${(props) => props.theme.colors.orange_40};
  text-transform: uppercase;
  font-weight: 600;
`;

const Div = styled.div`
  margin-bottom: 24px;
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }

  a.tertiary {
    color: #ff6c37;
  }
  a.tertiary:hover {
    border-bottom: 1px solid #ff6c37;
  }
`;

const LottieContainer = styled.div`
  padding: 0 24px;
  @media (min-width: 1199px) {
    padding: 0 80px;
  }
`;

const LightButton = styled(Secondary)`
  border: 1px solid #ffffff !important;
  color: ${(props) => props.theme.colors.grey_00} !important;
  &:hover,
  &:active {
    opacity: 0.8;
    -webkit-text-decoration: none;
    -moz-text-decoration: none;
    text-decoration: none;
  }

  &.ai-agent-button {
    background-color: #E8D6FF;
    border: none !important;
    color: #710EED !important;

    &:hover, &:active {
      opacity: 1;
      transition: all 0.3s ease-in-out;
      background-color: #710EED;
      color: #ffffff !important;
      border: none !important;
    }
  }
`;

const heroWithModalAndImage = (data) => {
  const colLeftClass = data.colLeft ? `col-lg-${data.colLeft}` : 'col-lg-6';
  const colRightClass = data.colRight ? `col-lg-${data.colRight}` : 'col-lg-6';
  return (
    <>
      <Section
        paddingTop={data.paddingTop || null}
        paddingBottom={data.paddingBottom || null}
        style={{
          background: data.background,
          backgroundColor: data.backgroundColor
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className={`col-sm-12 ${colLeftClass} text-lg-left text-center align-self-center order-2 order-lg-1 mt-5 mt-sm-0`}
            >
              {data.link && (
                <div className="mb-4">
                  <BaseLink
                    className="text-white"
                    src={data.link.src}
                    linkType="reversedArrowLink"
                  >
                    {data.link.text}
                  </BaseLink>
                </div>
              )}
              {data.parentTitle && (
                <div className="row">
                  <div className="col-12">
                    <ParentTitleText>{data.parentTitle}</ParentTitleText>
                  </div>
                </div>
              )}
              {data.parentTitleTemp && (
                <div className="row">
                  <div className="col-12">
                    <ParentTitleTemp className="mb-0">
                      {data.parentTitleTemp}
                    </ParentTitleTemp>
                  </div>
                </div>
              )}
              {data.titles && (
                <h1 className={`mb-4 ${data.className}`}>{data.titles}</h1>
              )}
              {data.parentSubtitle && (
                <div className="row">
                  <div className="col-12">
                    <ParentSubtitleText>
                      {data.parentSubtitle}
                    </ParentSubtitleText>
                  </div>
                </div>
              )}
              {data.body && (
                <Paragraph
                  className={`subtitle ${data.className} ${data.bodyClassName}`}
                >
                  {data.body}
                </Paragraph>
              )}
              {Array.isArray(data.bodyHTML) && (
                <Div
                  dangerouslySetInnerHTML={{ __html: data.bodyHTML.join('') }}
                />
              )}
              {data.cta && !data.cta.mode && (
                <BaseButton
                  as="a"
                  buttonType={data.cta.buttonType}
                  src={data.cta.src}
                  target={data.cta.target}
                  className={data.cta.className}
                >
                  {data.cta.text}
                </BaseButton>
              )}
              {data.cta && data.cta.mode === 'dark' && (
                <LightButton
                  buttonType="secondary"
                  rel="noreferrer"
                  className={`mt-3 ${data.cta.className}`}
                  as="a"
                  id={data.cta.id}
                  href={data.cta.href}
                  target={data.cta.target}
                  dataTest={data.cta.target}
                >
                  {data.cta.text}
                </LightButton>
              )}
              {data.modal && (
                <ModalComponent
                  className={` ${data.button.className || 'video-btn'}`}
                  modal={data.modal}
                  button={data.button}
                  isSecondary={data.button.isSecondary}
                />
              )}
              {data.links && !data.links.mode && (
                <BaseButton
                  buttonType="secondary"
                  className={`mt-3 ${data.className}`}
                  as="a"
                  src={data.links.src}
                  target={data.links.target}
                  dataTest={data.links.target}
                >
                  {data.links.text}
                </BaseButton>
              )}
               {data.links && data.links.mode === 'dark' && (
                <LightButton
                  buttonType="secondary"
                  rel="noreferrer"
                  className={`mt-3 ${data.links.className}`}
                  as="a"
                  id={data.links.id}
                  href={data.links.href}
                  target={data.links.target}
                  dataTest={data.links.target}
                >
                  {data.links.text}
                </LightButton>
              )}
              {data.isSignUpForm && (
                <div className="row">
                  <div className="col-12 offset-0 col-md-8 offset-md-2 offset-lg-0 col-lg-9">
                    <SignUpPostmanEmail />
                  </div>
                </div>
              )}
              {data.ctaSales && (
                <a
                  className="salesCta"
                  style={{ color: '#3D99FF' }}
                  href={data.ctaSales.href}
                >
                  {data.ctaSales.text} →
                </a>
              )}
              {data.footer && (
                <div className="row d-none d-lg-flex flex-row pt-4">
                  <div className="col-12">
                    <div
                      dangerouslySetInnerHTML={{ __html: data.footer.text }}
                    />
                  </div>
                  <div className="col-12 d-none d-lg-flex flex-row justify-content-center justify-content-lg-start">
                    {data.footer.links.map((link) => (
                      <a
                        key={pmUuid()}
                        data-test={link.dataTest}
                        className="inline mr-3"
                        href={link.href}
                      >
                        {link.image && (
                          <SVGComponent
                            svgContent={link.image.src}
                            fill={link.image.fill}
                            hoverFillColor={link.image.hoverFillColor}
                          />
                        )}
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div
              className={`col-sm-12 ${colRightClass} align-self-center order-1 order-lg-2 text-center col-sm-12 col-lg-5 align-self-center order-1 order-lg-2 text-center mb-2 mb-lg-0 `}
            >
              {data.media[0].isLottie && (
                <LottieContainer>
                  <LottiAnimationContainer
                    autoplay={data.media[0].autoplay}
                    loop={data.media[0].loop}
                    animationFileName={data.media[0].lottieFileName}
                  />
                </LottieContainer>
              )}
              {!data.media[0].isLottie ? (
                <img
                  className="img-fluid"
                  src={data.media[0].image}
                  alt={data.media[0].alt}
                  fetchpriority="high"
                  loading="eager"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </Section>
      {(data.divider !== false && <Divider fullWidth />) || null}
      <span data-sticky={data.stickyAttribute || null} />
    </>
  );
};

export default heroWithModalAndImage;
