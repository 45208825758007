import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
/* eslint-enable */

import { Divider, SectionStyles, BaseButton } from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const EmbedContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  z-index: 0; /* Safari rounded corner bug hack */
  &.fullwrap {
    border-radius: ${(props) => props.theme.borderRadius.large};
    border: 2px solid #c3b5e6;
    -webkit-box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
    box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
  }
  &.top-bottom {
    border-top: 4px solid #e6e6e6;
    border-bottom: 4px solid #e6e6e6;
    -webkit-box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
    box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
    max-width: 1600px;
    margin: auto;
    display: block;
    @media screen and (min-width: 1600px) {
      border: 4px solid #e6e6e6;
      border-radius: ${(props) => props.theme.borderRadius.large};
    }
  }
  &.secondary-border {
    border: 1px solid rgb(230, 230, 230);
    border-radius: 10px;
  }
`;

function HeroStackedVideo({
  h1,
  body,
  bodyAlignment,
  border,
  controls,
  autoPlay,
  muted,
  loop,
  divider,
  videoEmbed,
  size,
  paddingTop,
  paddingBottom,
  ctaButton,
  poster,
  backgroundImage,
  videoType,
  videoId,
  vttFile
}) {
  // Determine video width
  const layoutString = size.toLowerCase();
  let colWidth;
  switch (layoutString) {
    case 'small':
      colWidth = 'col-lg-6 offset-lg-3';
      break;
    case 'medium':
      colWidth = 'col-md-8 offset-md-2';
      break;
    case 'large':
      colWidth = 'col-lg-10 offset-lg-1';
      break;
    case 'full-width':
      colWidth = '';
      break;
    default:
      // everything else defaults to layout 1L
      colWidth = 'col-md-8 offset-md-2';
  }

  return (
    /* eslint-disable jsx-a11y/media-has-caption */
    <div>
      <SectionStyles
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
        style={{
          backgroundImage: `url('${backgroundImage}')`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'transparent'
        }}
      >
        <div className="container">
          <div className="row no-gutters">
            <div className={`col-12 ${colWidth}`}>
              <EmbedContainer
                className={`mb-5 embed-responsive embed-responsive-16by9 ${border}`}
              >
                <div>
                  <video
                    playsInline
                    id={videoId}
                    preload="metadata"
                    src={videoEmbed}
                    type={videoType}
                    poster={poster}
                    controls={controls}
                    autoPlay={autoPlay}
                    loop={loop}
                    muted={muted}
                  >
                    <track
                      label="English"
                      kind="subtitles"
                      srcLang="en"
                      src={vttFile}
                      default
                    />
                    Sorry, your browser doesn&apos;t support embedded videos.
                  </video>
                </div>
              </EmbedContainer>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {/* TOP - IMAGE */}
            <div className="col-12 text-center">
              {h1 && (
                <h1
                  className="text-white"
                  dangerouslySetInnerHTML={{ __html: h1.join('') }}
                />
              )}
            </div>
            <div
              className={`'col-12 col-md-8 offset-md-2${
                bodyAlignment ? ` text-${bodyAlignment}` : ''
              }'`}
            >
              {body && (
                <div dangerouslySetInnerHTML={{ __html: body.join('') }} />
              )}

              {ctaButton &&
                Array.isArray(ctaButton) &&
                ctaButton.map((cta) => (
                  <div className="mb-4 text-center" key={pmUuid()}>
                    <BaseButton
                      buttonType={cta.buttonType}
                      as="a"
                      src={cta.src}
                      target={cta.target}
                      id={cta.id}
                      gaCategory={cta.gaCategory}
                      gaLabel={cta.gaLabel}
                      className={cta.type === 'inline' ? 'pl-0' : null}
                      icon={cta.icon}
                      dataTest={cta.id}
                    >
                      {cta.text}
                    </BaseButton>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </SectionStyles>
      {(divider !== false && <Divider fullWidth />) || null}
    </div>
    /* eslint-enable */
  );
}
HeroStackedVideo.propTypes = {
  backgroundImage: PropTypes.string,
  h1: PropTypes.arrayOf(PropTypes.string).isRequired,
  body: PropTypes.arrayOf(PropTypes.string).isRequired,
  bodyAlignment: PropTypes.string,
  border: PropTypes.string,
  divider: PropTypes.bool,
  videoEmbed: PropTypes.string.isRequired,
  videoType: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  vttFile: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  ctaButton: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string,
      src: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string,
      gaCategory: PropTypes.string,
      gaLabel: PropTypes.string,
      icon: PropTypes.string
    })
  ),
  poster: PropTypes.string
};

HeroStackedVideo.defaultProps = {
  backgroundImage: null,
  bodyAlignment: null,
  border: null,
  divider: null,
  paddingTop: '60px',
  paddingBottom: '60px',
  ctaButton: null,
  controls: false,
  autoPlay: false,
  muted: false,
  loop: false,
  poster: null
};
// Use "aboveSplit": true, to render hero above col split on splitTemplate.
// This key is handled on the template level and NOT in this jsx component.

// Example :
// "type": "heroBasicSection",
// "h1": "Lorem Ipsum Dolor Sit", (required)
// "subtitle": "Eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.", (optional)
// "buttonLeft": { (optional)
//   "buttonType": "primary", ("primary" or "inline", refer to v5_Button for more options)
//   "text": "Primary",
//   "href": "/",
//   "target": "same-tab", (internal, same tab, new-tab-postman = _blank, noopener, new-tab-external-company = _blank, noopener noreferrer)
//   "id": "testingID",
//   "gaCategory": "primary-ga-category",
//   "gaLabel": "primary-ga-label"
// },
// "buttonRight": { (optional, see above)
//   "buttonType": "primary",
//   "text": "Secondary Button",
//   "href": "/customers/",
//   "target": "new-tab-postman",
//   "id": "secondaryTestingID",
//   "gaCategory": "secondary-ga-category",
//   "gaLabel": "secondary-ga-label"
// },
// "media": { (required)
//   "src": "https://voyager.postman.com/post-con/postcon-hero.svg", (required)
//   "alt": "Postman alt" (required)
// },
// aboveSplit: true

export default HeroStackedVideo;
