/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/function-component-definition */
import React from 'react';
import styled from 'styled-components';
import { Divider, BaseButton, Secondary } from 'aether-marketing';
// eslint-disable-next-line import/no-extraneous-dependencies
const pmUuid = require('@postman/uuid');
// eslint-enable

const BlurShape = styled.img`
  @keyframes move {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0);
    }
  }

  position: absolute;
  width: 50vw;
  filter: blur(80px);
  -webkit-filter: blur(80px);
  right: 0%;
  top: 60px;
  animation: 10s linear infinite, move 20s ease-in-out infinite;
  transform-origin: center;
  @media screen and (max-width: 992px) {
    right: 25%;
  }
`;
const Section = styled.section`
  background-color: ${(props) => props.backgroundColor};
  padding-top: ${(props) => props.paddingTop || `60px`};
  padding-bottom: ${(props) => props.paddingBottom || `60px`};
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 992px) {
    padding: 60px 0;
  }
  @media screen and (max-width: 576px) {
    padding: 30px 0;
  }
`;
const ParentTitleTemp = styled.p`
  color: ${(props) => props.theme.colors.grey_90};
  font-weight: 600;
`;

const ParentTitleText = styled.p`
  color: ${(props) => props.theme.colors.orange_40};
  text-transform: uppercase;
  font-weight: 600;
`;

const ParentSubtitleText = styled.p`
  color: ${(props) => props.theme.colors.orange_40};
  text-transform: uppercase;
  font-weight: 600;
`;
const Paragraph = styled.div`
  font-size: 22px;
  font-weight: 400;
  margin: 0px 0px 32px;
  line-height: 1.45455 !important;
  letter-spacing: -0.12px !important;
`;

const SecondaryButton = styled(Secondary)`
  border: 1px solid #ffffff !important;
  color: ${(props) => props.theme.colors.grey_00} !important;
  &:hover,
  &:active {
    opacity: 0.8;
    -webkit-text-decoration: none;
    -moz-text-decoration: none;
    text-decoration: none;
  }

  &.ai-agent-button {
    background-color: #E8D6FF;
    border: none !important;
    color: #710EED !important;

    &:hover, &:active {
      opacity: 1;
      transition: all 0.3s ease-in-out;
      background-color: #710EED;
      color: #ffffff !important;
      border: none !important;
    }
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: left;
  }

  .button {
    margin-top: 1rem;

    @media (min-width: 992px) {
      margin-top: 0;
    }
  }
`;

const CalloutDark = (data) => {
  const colLeftClass = data.colLeftClass || 'col-lg-6';
  const colRightClass = data.colRightClass || 'col-lg-6';
  return (
    <>
      <Section
        paddingTop={data.paddingTop}
        paddingBottom={data.paddingBottom}
        style={{
          background: data.background,
          backgroundColor: data.backgroundColor
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className={`col-sm-12 ${colLeftClass} text-lg-left text-center align-self-center order-2 order-lg-1 mt-5 mt-sm-0`}
            >
              {data.parentTitle && (
                <div className="row">
                  <div className="col-12">
                    <ParentTitleText>{data.parentTitle}</ParentTitleText>
                  </div>
                </div>
              )}
              {data.parentTitleTemp && (
                <div className="row">
                  <div className="col-12">
                    <ParentTitleTemp>{data.parentTitleTemp}</ParentTitleTemp>
                  </div>
                </div>
              )}
              <h2 className={`${data.className}`}>{data.titles}</h2>
              {data.parentSubtitle && (
                <div className="row">
                  <div className="col-12">
                    <ParentSubtitleText>
                      {data.parentSubtitle}
                    </ParentSubtitleText>
                  </div>
                </div>
              )}
              <Paragraph
                className={`${data.className}`}
                dangerouslySetInnerHTML={{ __html: data.body.join('') }}
              />
              {data.cta && (
                <BaseButton
                  as="a"
                  buttonType={data.cta.buttonType}
                  src={data.cta.src}
                  target={data.cta.target}
                  className={data.cta.className}
                >
                  {data.cta.text}
                </BaseButton>
              )}
              {data.links && (
                <ButtonContainer>
                  <SecondaryButton
                    buttonType="secondary"
                    className={`button ${data.links.className}`}
                    as="a"
                    href={data.links.src}
                    rel={data.links.rel}
                    target={data.links.target}
                    dataTest={data.links.target}
                    id={data.links.id}
                    gaCategory={data.links.gaCategory}
                    gaLabel={data.links.gaLabel}
                  >
                    {data.links.text}
                  </SecondaryButton>
                  {data.linksTwo && (
                    <BaseButton
                      buttonType={data.linksTwo.buttonType}
                      as="a"
                      className={`button ${data.linksTwo.className}`}
                      src={data.linksTwo.src}
                      target={data.linksTwo.target}
                      rel={data.links.rel}
                      id={data.linksTwo.id}
                      gaCategory={data.linksTwo.gaCategory}
                      gaLabel={data.linksTwo.gaLabel}
                      icon={data.linksTwo.icon}
                    >
                      {data.linksTwo.text}
                    </BaseButton>
                  )}
                </ButtonContainer>
              )}
              {data.footer && (
                <div className="row d-flex flex-row">
                  <div className="col-12">
                    <div
                      dangerouslySetInnerHTML={{ __html: data.footer.text }}
                    />
                  </div>
                  <div className="col-12">
                    {data.footer.links.map((link) => (
                      <a
                        key={pmUuid()}
                        className="inline mr-3"
                        href={link.href}
                      >
                        {link.image && (
                          <img
                            src={link.image.src}
                            alt={link.image.alt}
                            className="img-fluid"
                          />
                        )}
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div
              className={`col-sm-12 ${colRightClass} align-self-center order-1 order-lg-2 text-center mb-3 mb-lg-0`}
            >
              {data.media && (
                <img
                  className={
                    data.media[0].fullSize ? '' : 'img-fluid position-relative'
                  }
                  style={{ zIndex: 1 }}
                  src={data.media[0].image}
                  alt={data.media[0].alt}
                  fetchpriority="high"
                  loading="eager"
                />
              )}
            </div>
          </div>
          {data.backgroundTransition && (
            <BlurShape src={data.backgroundTransition.src} />
          )}
        </div>
      </Section>
      {data.divider && <Divider fullWidth />}
    </>
  );
};

export default CalloutDark;
