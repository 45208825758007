import React from 'react';
import styled from 'styled-components';
import { BaseButton, UnorderedListStyles } from 'aether-marketing';
import PostconSectionHeader from './PostconSectionHeader';
// import SingleInputForm from './forms/layout/SingleInputForm';
import PostconResponsiveImage from './PostconResponsiveImage';
import FormV7 from './forms/layout/FormV7';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const StylesWrapper = styled.section`
  z-index: 2;
  position: relative;
  padding-top: ${(props) => props.paddingTop || '30px'};
  padding-bottom: 30px;
  background: ${(props) => props.backgroundColor};

  p {
    color: white;
  }
  .gradientLine {
    margin: 30px 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, #ff6c37 -5.31%, #c74eda 100%);
  }
  .bg-container {
    height: 7vw;
  }
  .event-time {
    color: #ff6c37;
    font-weight: bold;
    padding-top: 3px;
  }

  form label,
  form input {
    color: white !important;
  }
  ul {
    ${UnorderedListStyles.componentStyle.rules}
    padding-left: 0;
  }
  li {
    color: white !important;
  }
  h2 > span {
    color: #ff6d38;
  }
  @media (min-width: 992px) {
    padding-top: ${(props) => props.paddingTop || '60px'};
    padding-bottom: ${(props) => props.paddingBottom || '60px'};
  }
  .sunRight {
    border-radius: 50%;
    width: 166px;
    height: 166px;
    background: rgba(255, 180, 34, 0.81);
    background: radial-gradient(circle, #ff6d38 40%, #ffb422);
    position: absolute;
    bottom: -50px;
    right: -105px;
    margin-left: -113px;
    overflow: hidden;
    -webkit-box-shadow: 0 10px 6px -6px #ff6c37;
    -moz-box-shadow: 0 10px 6px -6px #ff6c37;
    box-shadow: 0 0px 26px 6px #ff6c37;
    z-index: 2;
    @media (max-width: 1280px) {
      display: none;
    }
    @media (min-width: 1800px) {
      position: relative;
      margin-left: 0px;
      bottom: -50px;
      right: -1200px;
    }
  }
  .border {
    background: linear-gradient(#210b30, #210b30) padding-box,
      linear-gradient(255deg, #ff6c37, #c74eda) border-box;
    border-radius: 10px;
    border: 1px solid transparent;
    color: white;
    overflow: hidden;
  }
  .img-mobile {
    display: block;
  }
  .img-crowd {
    display: none;
  }
  @media (min-width: 600px) {
    .img-mobile {
      display: none;
    }
    .img-crowd {
      display: block;
    }
  }

  // a {
  //   color: white;
  //   text-decoration: none;
  //   border-bottom: 1px solid white;
  // }
`;

const ContentWrapper = styled.div`
  color: white;
  .time {
    color: #ff6c37;
    font-weight: bold;
    /* margin-left: 25px; */
  }

  .body a {
    color: white;
    border-bottom: 1px solid white;
  }
  .body a:hover {
    color: #ff6c37;
    text-decoration: none;
    border-bottom: 1px solid #ff6c37;
  }

  .body a:active {
    color: #e05320;
  }

  .gradientText {
    background-image: linear-gradient(to right, #ff6c37, #c64dda);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
    font-size: 36px;
  }

  .calendarItems {
    color: white;
  }

  .calendarItems:hover,
  .calendarItems:active {
    color: #ff6c37;
  }

  #postcon-form {
    background-color: transparent;
    padding: 24px 0;
  }

  input,
  textarea {
    border: 1px solid #ff6c37;
    border-radius: 4px !important;
    height: 42px;
    background: transparent !important;
    border-radius: 10px 0 0 10px;
    color: white;
  }
`;
const BorderStyles = styled.div`
  background: linear-gradient(#210b30, #210b30) padding-box,
    linear-gradient(255deg, #ff6c37, #c74eda) border-box;
  border-radius: 10px;
  border: 1px solid transparent;
  color: white;
  overflow: hidden;
`;

/* eslint-disable react/prop-types */
function PostconSideBySide({
  title,
  subtitle,
  button,
  backgroundImage,
  backgroundImageMobile,
  logo,
  body,
  photo,
  backgroundColor,
  headerTitle,
  preTitle,
  headerBody,
  headerLink,
  paddingBottomHeader,
  sun,
  sunRight,
  layout,
  textColor,
  items,
  useFullHeight,
  calendarHeader,
  calendarItems,
  headerClass,
  paddingTop,
  paddingBottom,
  eyebrow,
  form,
  contentPaddingTop,
  rightItems,
  map,
  video,
  imageTitle
}) {
  const layoutString = layout.toUpperCase();
  let colText;
  let colMedia;
  let colTextRight;
  switch (layoutString) {
    case '1R':
      colMedia = 'col-lg-5 offset-lg-0 mb-5 mb-lg-0';
      colText = 'col-lg-6 offset-lg-1 col-md-8 offset-md-2 align-self-center';
      break;
    case '1L':
      colText = 'col-lg-6';
      colMedia = 'col-lg-6';
      break;
    case '1P':
      colText = 'col-lg-8 ';
      colMedia = 'col-lg-4 ';
      break;
    case '1F':
      colText = 'col-lg-7 align-items-center';
      colMedia = 'col-lg-3 offset-lg-1';
      break;
    case '1B':
      colText = 'col-12 col-lg-5 offset-lg-0 align-items-center';
      colMedia = 'col-12 col-lg-6 offset-lg-1';
      break;
    case '2T':
      colText = 'col-lg-6 align-items-center';
      colTextRight = 'col-lg-6 align-items-center';
      break;
    default:
      colText = 'col-12 col-lg-5 offset-lg-0 align-items-center';
      colMedia = 'col-12 col-lg-6 offset-lg-1';
  }
  const textSection = (
    <ContentWrapper
      className={`${colText}`}
      style={{ paddingTop: backgroundImage ? contentPaddingTop : '0' }}
    >
      {logo && (
        <div className="img-container">
          <img alt={logo.alt} src={logo.src} height="80px" />
        </div>
      )}
      {eyebrow && <p className="h3 gradientText mb-0">{eyebrow}</p>}
      {title && <h2 className={`h1 ${textColor || 'text-white'}`}>{title}</h2>}
      {subtitle && (
        <div
          dangerouslySetInnerHTML={{ __html: subtitle.join('') }}
          className="subtitle"
        />
      )}
      {body && (
        <div
          className="body"
          dangerouslySetInnerHTML={{ __html: body.join('') }}
        />
      )}
      {items && (
        <div>
          {items.map((item) => (
            <div key={pmUuid()}>
              <div className="row ">
                {item.icon && (
                  <img
                    src={item.icon}
                    alt={item.iconAlt}
                    className=""
                    style={{ paddingLeft: '1.2rem' }}
                    height="24px"
                  />
                )}

                {item.time && (
                  <span className="time ml-lg-4 col-lg-4">{item.time}</span>
                )}
                <div
                  className={`col-12  ${item.time ? 'col-lg-7' : 'col-lg-11'}`}
                >
                  <p
                    className="mb-0 text-white"
                    dangerouslySetInnerHTML={{
                      __html: item.description.join('')
                    }}
                  />
                </div>
              </div>
              <div className="gradientLine" />
            </div>
          ))}
        </div>
      )}
      {calendarHeader && (
        <p className="mb-3 text-white font-weight-bold">{calendarHeader}</p>
      )}
      {calendarItems && (
        <div className="row">
          {calendarItems.map((cal) => (
            <div key={pmUuid()}>
              <div className="col-6">
                <a
                  href={cal.url}
                  target="_blank"
                  rel="noreferrer"
                  className="calendarItems"
                >
                  {cal.logo && (
                    <div dangerouslySetInnerHTML={{ __html: cal.logo }} />
                  )}
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
      {button && (
        <BaseButton
          buttonType={button.buttonType}
          as="a"
          src={button.src}
          target={button.target}
          id={button.id}
          gaCategory={button.gaCategory}
          gaLabel={button.gaLabel}
          dataTest={button.id}
          className=""
        >
          {button.text}
        </BaseButton>
      )}
      {form && (
        <div className="ml-lg-0">
          {' '}
          {/* <SingleInputForm form={form} /> */}
          <FormV7 form={form} />
        </div>
      )}
    </ContentWrapper>
  );

  const textRightSection = (
    <ContentWrapper className={`${colTextRight}`}>
      {rightItems && rightItems.eyebrow && (
        <p className="h3 gradientText mb-0">{rightItems.eyebrow}</p>
      )}
      {rightItems && rightItems.title && (
        <h2 className={textColor || 'text-white'}>{rightItems.title}</h2>
      )}
      {rightItems && rightItems.subtitle && (
        <div
          dangerouslySetInnerHTML={{ __html: rightItems.subtitle.join('') }}
        />
      )}
      {rightItems && rightItems.body && (
        <div
          className="body"
          dangerouslySetInnerHTML={{ __html: rightItems.body.join('') }}
        />
      )}
    </ContentWrapper>
  );

  const mediaSection = (
    <div className={`${colMedia} mt-4 mt-lg-0`}>
      {map ? (
        <BorderStyles
          className="d-flex h-100"
          style={{ borderRadius: '10px', overflow: 'hidden' }}
        >
          <iframe
            title="POST/CON 24 location"
            style={{ border: 'none', borderRadius: '10px' }}
            width="100%"
            height="355"
            src="https://maps.google.com/maps?width=100%25&amp;height=355&amp;hl=en&amp;q=Google, Hilton San Francisco Union Square 333 O'Farrell St, San Francisco, CA, USA&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          />
        </BorderStyles>
      ) : null}{' '}
      {photo && photo.border ? (
        <BorderStyles
          className="d-flex h-100"
          style={{ borderRadius: '10px', overflow: 'hidden' }}
        >
          <img
            src={photo.src}
            className="img-fluid imageStyles"
            alt={photo.alt}
            loading="lazy"
          />
        </BorderStyles>
      ) : null}
      {photo && !photo.border ? (
        <>
          {imageTitle && (
            <h2 className={textColor || 'text-white text-center'}>
              {imageTitle}
            </h2>
          )}
          <img
            src={photo.src}
            className="img-fluid imageStyles"
            alt={photo.alt}
            loading="lazy"
            style={
              photo.imgBorder
                ? {
                    borderRadius: '16px',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 8px 8px 0px'
                  }
                : {}
            }
          />
        </>
      ) : null}
      {video && (
        <BorderStyles
          className="d-flex h-100"
          style={{ borderRadius: '10px', overflow: 'hidden' }}
        >
          <iframe
            style={{ border: 'none', borderRadius: '10px' }}
            width="100%"
            height="355"
            title={video.title}
            loading="lazy"
            className="embed-responsive-item"
            src={video.src}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            controls={video.controls}
            autoPlay={video.autoPlay}
            muted={video.muted}
            loop={video.loop}
            poster={video.poster}
          />
        </BorderStyles>
      )}
    </div>
  );

  return (
    <StylesWrapper
      className="hero"
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      style={{
        overflowX: sunRight ? 'clip' : '',
        height: useFullHeight ? '100vh' : 'auto'
      }}
    >
      <div className="container">
        {(headerTitle || preTitle) && (
          <div className="row">
            <PostconSectionHeader
              title={headerTitle}
              preTitle={preTitle}
              body={headerBody}
              paddingBottom={paddingBottomHeader}
              headerClass={headerClass}
              sun={sun}
              link={headerLink}
            />
          </div>
        )}
        {backgroundImage && backgroundImageMobile && (
          <div className="bg-container">
            <PostconResponsiveImage
              backgroundImage={backgroundImage.src}
              backgroundImageMobile={backgroundImageMobile.src}
              alt="background image"
            />
          </div>
        )}

        {layoutString === '1R' && (
          <div className="row align-items-center">
            {mediaSection}
            {textSection}
          </div>
        )}
        {(layoutString === '1L' ||
          layoutString === '1P' ||
          layoutString === '1F' ||
          layoutString === '1B') && (
          <div className="row align-items-center">
            {textSection}
            {mediaSection}
          </div>
        )}
        {layoutString === '2T' && (
          <div className="row">
            {textSection}
            {textRightSection}
          </div>
        )}
        {sunRight ? <div className="sunRight " /> : null}
      </div>
    </StylesWrapper>
  );
}
/* eslint-enable react/prop-types */
export default PostconSideBySide;
