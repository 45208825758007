import React, { useMemo } from 'react';
import { hydrateRoot } from 'react-dom/client';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SectionStyles, Paragraph, BaseLink } from 'aether-marketing';
import css from '../css.json';
import eventsData from '../../../bff-data/events.json';

// const eventsData = require('../../../fixture/events.json'); this is for local development

const runtime = typeof document === 'object';

const Card = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  box-shadow: ${(props) =>
    (props.theme.shadows && props.theme.shadows.card_shadow) ||
    css.shadows.card_shadow};
  transition: ${(props) =>
    (props.theme.transitions && props.theme.transitions.all) ||
    css.transitions.all};
  &:hover {
    transform: translateY(-0.5rem);
  }
`;

const CardInfo = styled.p`
  color: ${(props) =>
    (props.theme.colors && props.theme.colors.grey_90) || css.colors.grey_90};
  font-family: ${(props) =>
    (props.theme.fonts && props.theme.fonts.Inter) || css.fonts.Inter};
  font-size: 16px;
  text-transform: uppercase;
`;

const CardTitle = styled.h3`
  color: ${(props) =>
    (props.theme.colors && props.theme.colors.grey_90) || css.colors.grey_90};
`;

// const CardLink = styled.a`
//   width: 100%;
//   text-decoration: none;
//   &:hover {
//     text-decoration: none;
//     -webkit-transform: translateY(-0.5rem);
//     transform: translateY(-0.5rem);
//     box-shadow: ${(props) =>
//       (props.theme.shadows && props.theme.shadows.card_shadow) ||
//       css.shadows.card_shadow};
//     -webkit-box-shadow: ${(props) =>
//       (props.theme.shadows && props.theme.shadows.card_shadow) ||
//       css.shadows.card_shadow};
//     -moz-box-shadow: ${(props) =>
//       (props.theme.shadows && props.theme.shadows.card_shadow) ||
//       css.shadows.card_shadow};
//     -webkit-transition: ${(props) =>
//       (props.theme.transitions && props.theme.transitions.all) ||
//       css.transitions.all};
//     transition: ${(props) =>
//       (props.theme.transitions && props.theme.transitions.all) ||
//       css.transitions.all};
//     text-decoration: none;
//   }
// `;

const CardContentWrapper = styled.div`
  background: none;
  background-color: ${(props) =>
    (props.theme.colors && props.theme.colors.white) || css.colors.white};
  border-radius: ${(props) =>
    (props.theme.borderRadius && props.theme.borderRadius.medium) ||
    css.borderRadius.medium};
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 100%;
  padding-top: 56.25%; // 16:9 aspect ratio
  position: relative;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : ''};
  border-radius: 8px 8px 0 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 1.5rem;
    // padding: ${(props) => (props.isCustomImage ? '1rem' : '0')};
  }
`;
// const Tag = styled.div`
//   border: 1px solid
//     ${(props) =>
//       (props.theme.colors && props.theme.colors.grey_30) || css.colors.grey_30};
//   border-radius: ${(props) =>
//     (props.theme.borderRadius && props.theme.borderRadius.small) ||
//     css.borderRadius.small};
//   color: ${(props) =>
//     (props.theme.colors && props.theme.colors.grey_50) || css.colors.grey_50};
//   float: right;
//   font-size: 1.2rem;
//   font-weight: 600;
//   line-height: 1;
//   padding: 0.5rem 0.8rem;
//   text-transform: uppercase;
//   white-space: nowrap;

//   @media screen and (max-width: 576px) {
//     margin-right: 8px;
//     float: left;
//     white-space: normal;
//   }
// `;
const defaultImages = [
  {
    src: 'https://voyager.postman.com/illustration/api-design-blueprint-postman-illustration.svg',
    alt: 'Postman Event Default Image 1'
  },
  {
    src: 'https://voyager.postman.com/illustration/api-portal-window-illustration-postman.svg',
    alt: 'Postman Event Default Image 2'
  },
  {
    src: 'https://voyager.postman.com/illustration/community-postmanauts-postman-illustration.svg',
    alt: 'Postman Event Default Image 3'
  },
  {
    src: 'https://voyager.postman.com/illustration/postman-present.svg',
    alt: 'Postmanaut behind a pedastal'
  },
  {
    src: 'https://voyager.postman.com/illustration/postman-handshake-illustration.svg',
    alt: 'Two Postman shaking hands'
  }
];
const MarkdownWrapper = styled.div`
  color: ${(props) =>
    (props.theme.colors && props.theme.colors.grey_90) || css.colors.grey_90};
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 1rem;
`;
const parseMarkdown = (content) => {
  if (!content) return '';

  // Memoize the parsed result to ensure consistency
  if (typeof content !== 'string') return '';

  // Escape HTML characters first
  const escapedContent = content
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');

  // Parse markdown with more specific regex patterns
  const linkRegex = /\[([^\]]+)\]\(([^)"']+)\)/g;
  const boldRegex = /\*\*([^*]+)\*\*|__([^_]+)__/g;
  const italicRegex = /\*([^*]+)\*|_([^_]+)_/g;

  const parsed = escapedContent
    .replace(linkRegex, (_, textContent, url) => {
      const safeUrl = url.trim().replace(/["']/g, '');
      return `<a href="${safeUrl}" target="_blank" rel="noopener noreferrer">${textContent}</a>`;
    })
    .replace(boldRegex, (_, m1, m2) => `<strong>${m1 || m2}</strong>`)
    .replace(italicRegex, (_, m1, m2) => `<em>${m1 || m2}</em>`);

  return parsed
    .split('\n\n')
    .map((p) => p.trim())
    .filter(Boolean)
    .join('<br /><br />');
};

function MarkdownContent({ text }) {
  const parsed = useMemo(() => parseMarkdown(text), [text]);
  return (
    <MarkdownWrapper
      className="markdown-content"
      dangerouslySetInnerHTML={{ __html: parsed }}
    />
  );
}
MarkdownContent.propTypes = {
  text: PropTypes.string.isRequired
};

class EventCard extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;

    this.state = {
      data
    };
  }

  render() {
    const { data } = this.state;
    if (data.link.startsWith('https://www.postman.com')) {
      data.type = 'same-tab';
      data.checkType = 'same-tab';
      data.rel = '';
      data.gaCategory = 'events-homepage';
    } else {
      data.type = '_blank';
      data.checkType = 'new-tab-external-nofollow';
      data.rel = 'noopener noreferrer';
      data.gaCategory = 'events-homepage';
    }

    const formatDateRange = (startDate, endDate) => {
      const start = new Date(startDate);
      const end = endDate ? new Date(endDate) : null;

      // Format for single day events
      if (!end || startDate === endDate) {
        return start.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        });
      }

      // Same month and year
      if (
        start.getMonth() === end.getMonth() &&
        start.getFullYear() === end.getFullYear()
      ) {
        return `${start.getDate()}${
          end.getDate() ? `-${end.getDate()}` : ''
        } ${start.toLocaleDateString('en-GB', {
          month: 'long',
          year: 'numeric'
        })}`;
      }

      // Different months or years
      return `${start.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long'
      })} - ${end.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      })}`;
    };

    data.formattedDate = formatDateRange(data.date, data.endDate);

    const usedImageIndexes = new Set();

    const getImage = () => {
      if (data.imageUrl) {
        return {
          src: data.imageUrl,
          alt: `${data.title} event image`
        };
      }

      // Reset usedImageIndexes if all images have been used
      if (usedImageIndexes.size >= defaultImages.length) {
        usedImageIndexes.clear();
      }

      // Get available indexes
      const availableIndexes = Array.from(
        { length: defaultImages.length },
        (_, i) => i
      ).filter((index) => !usedImageIndexes.has(index));

      // Get random index from available ones
      const randomIndex = Math.floor(Math.random() * availableIndexes.length);
      const selectedIndex = availableIndexes[randomIndex];

      // Add to used set
      usedImageIndexes.add(selectedIndex);

      return defaultImages[selectedIndex];
    };

    return (
      <div className="col-12 col-lg-4 mb-4">
        <Card data-item className=" ">
          <div className="">
            <CardContentWrapper className="">
              <ImageContainer
                isCustomImage={!!data.imageUrl}
                backgroundColor={data.backgroundColor}
              >
                <img
                  src={getImage().src}
                  alt={getImage().alt}
                  className="img-fluid mb-4"
                />
              </ImageContainer>
            </CardContentWrapper>
            <CardContentWrapper className="p-4">
              {/* <Tag className="mb-2" data-location>
                  {data.location}
                </Tag> */}
              <CardInfo className="mb-3 d-flex align-items-center" data-date>
                <img
                  src="https://voyager.postman.com/icon/events-postman-icon.svg"
                  alt="calndar icon"
                  className="mr-2"
                  width="18px"
                />
                {data.formattedDate}
              </CardInfo>
              <CardInfo
                className="mb-3 d-flex align-items-center"
                data-location
              >
                <img
                  src="https://voyager.postman.com/icon/postman-region-icon.svg"
                  alt="globe icon"
                  className="mr-2"
                  width="18px"
                />
                {data.location}
              </CardInfo>

              <CardTitle className="h4 mb-2" data-title>
                {data.title}
              </CardTitle>

              <MarkdownContent text={data.description} />

              <div>
                <BaseLink
                  as="a"
                  src={data.link}
                  target={data.checkType}
                  rel={data.rel}
                  linkType="arrowLink"
                  gaLabel={data.slug}
                  gaCategory={data.gaCategory}
                >
                  Learn more
                </BaseLink>
              </div>
            </CardContentWrapper>
          </div>
        </Card>
      </div>
    );
  }
}

const eventDynamic = (data) => {
  if (runtime) {
    window.pmt('bff', [
      'events',
      (d) => {
        const container = document.body.querySelector('[data-items]');
        const items = JSON.parse(d);

        // Create runtime events element
        const runtimeEvents = (
          <>
            {items.map((item) => (
              <EventCard key={`${item.date}-${item.title}`} data={item} />
            ))}
          </>
        );

        // Use hydrateRoot instead of ReactDOM.hydrate
        hydrateRoot(container, runtimeEvents);
      },
      true
    ]);
  }

  return (
    <SectionStyles data-type="event">
      <div className="container">
        <div className="row">
          <div
            className={
              `${data.textAlign}`
                ? `col-8 offset-2 ${data.textAlign} `
                : `col-8 offset-2 text-left`
            }
          >
            <h2 className="mb-2" data-title>
              {data.title}
            </h2>
            <Paragraph
              data-subtitle
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html:
                  (Array.isArray(data.subtitle) && data.subtitle.shift()) ||
                  data.subtitle
              }}
            />
          </div>
        </div>
        <div
          className="row justify-content-center d-flex align-items-stretch"
          data-items
        >
          {eventsData.map((item) => {
            return <EventCard key={`${item.date}-${item.title}`} data={item} />;
          })}
        </div>
      </div>
    </SectionStyles>
  );
};

EventCard.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    date: PropTypes.string,
    url: PropTypes.string,
    location: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    backgroundColor: PropTypes.string
  }).isRequired
};

export default eventDynamic;
