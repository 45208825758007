const cards = [
  {
    id: 'Microsoft Teams',
    tags: ['Collaboration'],
    header: {
      text: 'Microsoft Teams'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>Microsoft Teams is a chat-based workspace available to all Microsoft Office 365 users. This integration makes it easier to share Postman resources through rich links you can use to perform actions from Teams, like watching or forking a collection. You can also get Postman updates about your Postman team directly in Microsoft Teams.</p>'
      ]
    },
    media: {
      alt: 'Microsoft Teams logo',
      src: 'https://voyager.postman.com/logo/external/microsoft-teams-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://go.pstmn.io/integrations-teams',
      target: 'new-tab-external-nofollow',
      text: 'Install',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'vscode',
    tags: ['API Testing'],
    header: {
      text: 'VS Code'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        "<p>The Postman VS Code extension enables you to develop and test your APIs in Postman directly from Visual Studio Code and Visual Studio Code Insiders. The VS Code extension also supports VSCodium, Cursor, and Windsurf.</p>"
      ]
    },
    media: {
      alt: 'V S Code logo',
      src: 'https://voyager.postman.com/logo/external/vscode.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://marketplace.visualstudio.com/items?itemName=Postman.postman-for-vscode',
      target: 'new-tab-external-nofollow',
      text: 'Install',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Slack',
    tags: ['Collaboration'],
    header: {
      text: 'Slack'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>The Postman app for Slack integration enables you to share Postman links with more context and send Postman notifications to a Slack channel. Links from Postman provide the option to perform actions directly in Slack, like watching or forking a collection. Notifications from Postman (e.g., about new and updated requests, collections and environments) can appear in a team channel, and/or you can receive personal notifications in Slack about monitors and team activity.</p>'
      ]
    },
    media: {
      alt: 'Slack logo',
      src: '/assets/partner-program/external/slack-horizontal-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://go.pstmn.io/integrations-slack',
      target: 'new-tab-external-nofollow',
      text: 'Install',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: '1Password',
    tags: ['Vault'],
    header: {
      text: '1Password Vault'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        "<p>Leverage 1Password's robust password management to store your API keys and secrets. Integrate seamlessly with Postman Vault to access them securely within your requests, eliminating the need for hardcoding and boosting overall security.</p>"
      ]
    },
    media: {
      alt: '1Password logo',
      src: 'https://voyager.postman.com/logo/external/1password-logo-blue.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/sending-requests/postman-vault/1password/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Apigee',
    tags: ['Gateway'],
    header: {
      text: 'Apigee'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        "<p><a href='https://cloud.google.com/apigee' target='_blank' rel='noopener noreferrer'>Apigee X</a> enables developers to manage access to their APIs by abstracting services behind a secure proxy layer. Once connected to Apigee X, you can view your API deployment status and history from within Postman.</p>"
      ]
    },
    media: {
      alt: 'Apigee logo',
      src: '/assets/partner-program/external/apigee-company-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/designing-and-developing-your-api/deploying-an-api/deploying-an-api-apigee/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'APIMatic',
    tags: ['Documentation'],
    header: {
      text: 'APIMatic'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        "<p>Postman's APIMatic integration converts a Postman Collection into an API description format such as Swagger, RAML, or API Blueprint, and then periodically backs up the resulting file on GitHub.</p>"
      ]
    },
    media: {
      alt: 'APIMatic logo',
      src: '/assets/partner-program/apimatic-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/apimatic/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'AWS API Gateway',
    tags: ['Gateway'],
    header: {
      text: 'AWS API Gateway'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>You can upload your API schemas directly to AWS API Gateway from Postman with this integration. It uses version 2 of the AWS API and only supports HTTP APIs with OpenAPI 3.0 schemas. Once the integration is configured, any new changes to your schema in Postman will also appear in your AWS API Gateway.</p>'
      ]
    },
    media: {
      alt: 'AWS API Gateway logo',
      src: 'https://voyager.postman.com/logo/external/aws-api-gateway-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/aws-api-gateway/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'AWS Secrets Manager',
    tags: ['Vault'],
    header: {
      text: 'AWS Secrets Manager '
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>Streamline your API testing workflow by securely storing and accessing your AWS credentials (access keys, secret keys, and more) directly within Postman requests using Vault. This eliminates the need to hardcode credentials, improving security and manageability.</p>'
      ]
    },
    media: {
      alt: 'AWS Secrets logo',
      src: 'https://voyager.postman.com/logo/external/aws-secrets-manager-icon-red.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/sending-requests/postman-vault/aws-secrets-manager/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Azure API Management',
    tags: ['Gateway'],
    header: {
      text: 'Azure API Management'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>View deployments for each of your Azure API Management services. You can see the revision history, changelog, and export history. You can also export your API definition from Postman to Azure API Management.</p>'
      ]
    },
    media: {
      alt: 'Azure API Mangagement logo',
      src: 'https://voyager.postman.com/logo/external/azure-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/designing-and-developing-your-api/deploying-an-api/deploying-an-api-azure/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Azure Key Vault',
    tags: ['Vault'],
    header: {
      text: 'Azure Key Vault'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>Fortify your Azure API security posture. Postman Vault integration allows you to securely store and access your Azure keys and secrets directly within Postman, eliminating the risk of exposure from manual management. Streamlining your workflow while prioritizing security.</p>'
      ]
    },
    media: {
      alt: 'Azure Key Vault logo',
      src: 'https://voyager.postman.com/logo/external/microsoft-logo-horizontal.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/sending-requests/postman-vault/azure-key-vault/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Azure DevOps',
    tags: ['Git'],
    header: {
      text: 'Azure DevOps'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        "<p>Back up your team's Postman Collections with Postman's Azure DevOps integration.</p>"
      ]
    },
    media: {
      alt: 'Azure DevOps logo',
      src: '/assets/partner-program/external/azure-devops-company-logos.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/azure-devops/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'BigPanda',
    tags: ['APM'],
    header: {
      text: 'BigPanda'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>BigPanda is an IT systems management platform that aggregates IT alerts to flag high-level IT incidents. View real-time alerts based on the results of your Postman Monitors.</p>'
      ]
    },
    media: {
      alt: 'BigPanda logo',
      src: '/assets/partner-program/external/bigpanda-transparent-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/bigpanda/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Bitbucket',
    tags: ['Git', 'CI/CD'],
    header: {
      text: 'Bitbucket'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        "<p>Back up your team's Postman Collections with Postman's Bitbucket integration.</p>"
      ]
    },
    media: {
      alt: 'Bitbucket logo',
      src: '/assets/partner-program/external/bitbucket-company-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/bitbucket/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Bitbucket Pipelines',
    tags: ['Postman Supported'],
    header: {
      text: 'Bitbucket Pipelines'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        "<p><a href='https://bitbucket.org/product/features/pipelines' rel='noopener noreferrer' target='_blank'>Bitbucket Pipelines</a> is a CI/CD service that's integrated with Bitbucket Cloud. View the status of builds or start a new build, all from within Postman.</p>"
      ]
    },
    media: {
      alt: 'Bitbucket Pipelines logo',
      src: '/assets/partner-program/external/bitbucket-pipelines-company-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/ci-integrations/bitbucket-pipelines/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'CircleCI ',
    tags: ['CI/CD'],
    header: {
      text: 'CircleCI '
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        "<p><a href='https://circleci.com/developer/orbs/orb/postman/newman' rel='noopener noreferrer' target='_blank'>CircleCI</a> is a cloud-based CI/CD platform. You can view the status of builds or start a new build, all from within Postman.</p>"
      ]
    },
    media: {
      alt: 'CircleCI logo',
      src: '/assets/partner-program/external/circle-ci-horizontal-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/ci-integrations/circleci/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Coralogix',
    tags: ['APM'],
    header: {
      text: 'Coralogix'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>Coralogix is a machine learning-powered log analytics platform that improves the delivery and maintenance process. Configure your Postman Monitors to send metrics to Coralogix, where you can visualize and compare them.</p>'
      ]
    },
    media: {
      alt: 'Coralogix logo',
      src: '/assets/partner-program/external/coralogix-horizontal-company-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/coralogix/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Datadog',
    tags: ['APM', 'API Monitoring'],
    header: {
      text: 'Datadog'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>Datadog is a monitoring service for cloud-scale applications. Configure your Postman Monitors to send metrics to Datadog, where you can visualize and compare them with other metrics.</p>'
      ]
    },
    media: {
      alt: 'Datadog logo',
      src: 'https://voyager.postman.com/logo/external/datadog-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/datadog/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Dropbox',
    tags: ['Storage'],
    header: {
      text: 'Dropbox'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>Back up and synchronize your Postman Collections on Dropbox, keeping your Postman Collections and other project files in one place.</p>'
      ]
    },
    media: {
      alt: 'Dropbox logo',
      src: 'https://voyager.postman.com/logo/external/dropbox-logo-horizontal.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/dropbox/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'GitHub',
    tags: ['Git'],
    header: {
      text: 'GitHub'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>Back up your Postman Collections to GitHub, a cloud-based hosting service for Git repositories.</p>'
      ]
    },
    media: {
      alt: 'GitHub logo',
      src: 'https://voyager.postman.com/logo/external/github-icon.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/github/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'GitHub Actions',
    tags: ['CI/CD'],
    header: {
      text: 'GitHub Actions'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        "<p>GitHub Actions is a CI/CD service that's integrated with GitHub. After you set up the integration, you can view the status of builds from within Postman.</p>"
      ]
    },
    media: {
      alt: 'GitHub Actions logo',
      src: '/assets/partner-program/external/github-actions-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/ci-integrations/github-actions/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'GitLab',
    tags: ['Git'],
    header: {
      text: 'GitLab'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>Back up your Postman Collections to GitLab, an open-source Git repository manager.</p>'
      ]
    },
    media: {
      alt: 'GitLab logo',
      src: 'https://voyager.postman.com/logo/external/gitlab-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/gitlab/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'GitLab CI/CD',
    tags: ['CI/CD'],
    header: {
      text: 'GitLab CI/CD'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>GitLab CI/CD is integrated with GitLab SaaS and GitLab self-managed. After you set up the integration, you can view the status of builds or start a new build, all from within Postman.</p>'
      ]
    },
    media: {
      alt: 'GitLab CI/CD logo',
      src: 'https://voyager.postman.com/logo/external/gitlab-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/ci-integrations/gitlab-ci/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'HashiCorp',
    tags: ['Vault'],
    header: {
      text: 'HashiCorp Vault'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        "<p>Enhance your API testing efficiency with Postman Vault integration. Leverage HashiCorp Vault's centralized secrets management to securely access and manage your API keys, passwords, and other secrets directly from Postman.</p>"
      ]
    },
    media: {
      alt: 'HashiCorp logo',
      src: 'https://voyager.postman.com/logo/external/hashicorp-logo-dark.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/sending-requests/postman-vault/hashicorp-vault/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'ilert',
    tags: ['API Monitoring', 'Incident Response'],
    header: {
      text: 'ilert'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>ilert is an end-to-end solution for alerting, on-call management, and status pages. Built and hosted in Germany, it helps companies worldwide increase their uptime. With ilert integration, users can receive Postman Monitors alerts via SMS, push, and voice notifications, and report incidents on status pages with ease.</p>'
      ]
    },
    media: {
      alt: 'ilert logo',
      src: 'https://voyager.postman.com/logo/external/ilert-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://docs.ilert.com/inbound-integrations/postman-monitors?utm_campaign=Postman&utm_source=integration&utm_medium=organic',
      target: 'new-tab-external-nofollow',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Jenkins',
    tags: ['CI/CD'],
    header: {
      text: 'Jenkins'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>Jenkins is an open-source automation server that can act as CI/CD hub. You can view the status of builds or start a new build, all from within Postman.</p>'
      ]
    },
    media: {
      alt: 'Jenkins logo',
      src: 'https://voyager.postman.com/logo/external/jenkins-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/ci-integrations/jenkins/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Keen',
    tags: ['APM'],
    header: {
      text: 'Keen'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>You can use Keen IO for API-based computation, Amazon S3 backups, and building visualizations and dashboards for your APIs. Connect your Postman Monitor results to Keen Streams with this Integration.</p>'
      ]
    },
    media: {
      alt: 'Keen logo',
      src: '/assets/partner-program/external/keen-io-company-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/keen/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Microsoft Power Automate',
    tags: ['Workflows'],
    header: {
      text: 'Microsoft Power Automate'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>Configure Microsoft Power Automate with Postman to monitor run results, view team and collection-specific activity feeds, back up your Postman Collections, and use a Microsoft Power Automate Webhook URL.</p>'
      ]
    },
    media: {
      alt: 'Microsoft Power Automate logo',
      src: 'https://voyager.postman.com/logo/external/microsoft-power-automate-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/microsoft-power-automate/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'New Relic',
    tags: ['APM', 'API Monitoring'],
    header: {
      text: 'New Relic'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        "<p>New Relic is an application performance management solution to monitor real-time and trending data for your processes or web apps. Using Postman's New Relic integration, you can send Postman Monitor results to New Relic.</p>"
      ]
    },
    media: {
      alt: 'New Relic logo',
      src: 'https://voyager.postman.com/logo/external/new-relic-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/new-relic/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Open API',
    tags: ['Open Specification'],
    header: {
      text: 'Open API'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>You can import your existing OpenAPI 3.0 and 3.1 definitions (OpenAPI Specification) into Postman. Postman supports both YAML and JSON formats. You can choose to upload a file, enter a URL, or directly copy your JSON/YAML.</p>'
      ]
    },
    media: {
      alt: 'Open API logo',
      src: 'https://voyager.postman.com/logo/external/open-api-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/working-with-openAPI/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Opsgenie',
    tags: ['APM', 'Incident Response'],
    header: {
      text: 'Opsgenie'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>Opsgenie is an incident management and alerting tool that enables you to manage alerts. Configure Postman Monitors to trigger alerts on Opsgenie whenever your monitor fails. Alerts automatically close after a successful run.</p>'
      ]
    },
    media: {
      alt: 'Opsgenie logo',
      src: '/assets/partner-program/external/opsgenie-horizontal-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/opsgenie/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'PagerDuty',
    tags: ['APM', 'Incident Response'],
    header: {
      text: 'PagerDuty'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>PagerDuty is an incident management solution that integrates with monitoring stacks for alerting, on-call scheduling, and automatic escalation of critical incidents. This integration triggers incidents in PagerDuty based on your Postman Monitor results so that your team can investigate and resolve collection run failures.</p>'
      ]
    },
    media: {
      alt: 'PagerDuty logo',
      src: '/assets/partner-program/external/pagerduty-horizontal-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/pagerduty/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Splunk',
    tags: ['APM', 'Incident Response'],
    header: {
      text: 'Splunk'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>Splunk is a monitoring service for cloud-scale applications. It combines data from servers, databases, tools, and services to present a unified view of an entire stack. Configure your Postman Monitors to send metrics to Splunk where you can visualize and compare them with other metrics.</p>'
      ]
    },
    media: {
      alt: 'Splunk logo',
      src: 'https://voyager.postman.com/logo/external/splunk-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/splunk/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Splunk On-Call',
    tags: ['Collaboration '],
    header: {
      text: 'Splunk On-Call'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        "<p>Splunk On-Call (formerly VictorOps) is a real-time incident management platform that handles incidents as they occur and prepares for the next one. Configure <a href='https://learning.postman.com/docs/monitoring-your-api/intro-monitors/'>Postman Monitors</a> to trigger incidents on Splunk On-Call whenever it fails.</p>"
      ]
    },
    media: {
      alt: 'Splunk On-Call logo',
      src: '/assets/partner-program/external/splunk-oncall-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/splunk-on-call/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Statuspage',
    tags: ['APM', 'Incident Response'],
    header: {
      text: 'Statuspage'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        "<p>Atlassian Statuspage is an uptime and incident communication tools. You can use Statuspage to create a home page for your customers so they can monitor if subsystems or services within your site are operational. An example of a Statuspage home page is Postman's status page, located at status.postman.com. This integration enables you to send metrics like response times to Statuspage and create alerts in case of a failure. When a Postman monitor test run fails, this integration can then send updates to Statuspage.</p>"
      ]
    },
    media: {
      alt: 'Statuspage logo',
      src: '/assets/partner-program/external/statuspage-horizontal-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/statuspage/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Travis CI',
    tags: ['CI/CD'],
    header: {
      text: 'Travis CI'
    },
    body: {
      text: [
        "<p class='small'>Postman Supported</p>",
        '<p>Travis CI is a platform for automatically building and testing code changes. You can view the status of builds or start a new build, all from within Postman.</p>'
      ]
    },
    media: {
      alt: 'Travis CI logo',
      src: '/assets/partner-program/external/travis-ci-horizontal-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://learning.postman.com/docs/integrations/available-integrations/ci-integrations/travis-ci/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Helios',
    tags: ['API Observability'],
    header: {
      text: 'Helios'
    },
    body: {
      text: [
        "<p class='small'>Vendor Supported</p>",
        "<p>Helios is an observability, troubleshooting, and testing platform for microservices. Leveraging Helios's built-in flow replay capabilities, it is simple to generate the code required to re-run a certain flow, configure it, and export to a file that can be imported into Postman.</p>"
      ]
    },
    media: {
      alt: 'Helios logo',
      src: '/assets/partner-program/external/helios-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://blog.postman.com/postman-integration-with-helios-opentelemetry-data/',
      target: 'same-tab',
      text: 'Read the blog',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'AppMap',
    tags: ['API Visualization'],
    header: {
      text: 'AppMap'
    },
    body: {
      text: [
        "<p class='small'>Vendor Supported</p>",
        "<p><a href='https://appmap.io/' target='_blank' rel='noopener noreferrer'>AppMap</a> is an open source tool thousands of developers use to visualize their application's runtime behavior. Commit, publish, or import your OpenAPI specification into Postman to interact and test your application.</p>"
      ]
    },
    media: {
      alt: 'AppMap logo',
      src: '/assets/partner-program/external/appmap-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://appmap.io/docs/reference/integrations/postman.html',
      target: 'new-tab-external-nofollow',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'APIsec',
    tags: [''],
    header: {
      text: 'APIsec'
    },
    body: {
      text: [
        "<p class='small'>Vendor Supported</p>",
        '<p>EthicalCheck allows users to take a Postman Collection and run it against hundreds of API security tests across the OWASP API security list. These tests cover modern attack types and save developers time and resources as they build and change APIs in Postman.</p>'
      ]
    },
    media: {
      alt: 'APIsec logo',
      src: '/assets/partner-program/external/apisec-horizontal-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://blog.postman.com/postman-and-apisec-ethicalcheck-integration-better-security-practices/',
      target: 'same-tab',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Workato',
    tags: ['API Automation'],
    header: {
      text: 'Workato'
    },
    body: {
      text: [
        "<p class='small'>Vendor Supported</p>",
        '<p>Workato helps automate business workflows across cloud and on-premises apps. Increase visibility of any API collection by syncing it to your Postman workspace. There, you can make use of the internal and external portal to drive adoption.</p>'
      ]
    },
    media: {
      alt: 'Workato logo',
      src: '/assets/partner-program/external/workato-stacked-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://docs.workato.com/api-mgmt/api-collections.html#sync-to-postman',
      target: 'new-tab-external-nofollow',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Speedscale',
    tags: ['API Testing'],
    header: {
      text: 'Speedscale'
    },
    body: {
      text: [
        "<p class='small'>Vendor Supported</p>",
        "<p>Speedscale can export and import traffic in the open source Postman <a href='https://github.com/postmanlabs/postman-collection' rel='noopener noreferrer' target='_blank'>collection format</a>. This lets you use your familiar tools to send test transactions to your app with traffic that was collected by Speedscale.</p>"
      ]
    },
    media: {
      alt: 'Speedscale logo',
      src: '/assets/partner-program/external/speedscale-stacked-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://speedscale.com/auto-generate-postman-collections/',
      target: 'new-tab-external-nofollow',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  },
  {
    id: 'Pynt',
    tags: ['API Security'],
    header: {
      text: 'Pynt'
    },
    body: {
      text: [
        "<p class='small'>Vendor Supported</p>",
        '<p>Pynt is a free API security solution that runs seamlessly in Postman. It generates automated security tests based on your existing functional test collection. Simply input your functional test collection name into the Pynt collection, and run the API security test collection in your workspace.</p>'
      ]
    },
    media: {
      alt: 'Pynt logo',
      src: '/assets/partner-program/external/pynt-stacked-logo.png',
      ratio: '2/1'
    },
    link: {
      src: 'https://docs.pynt.io/documentation/security-testing-integrations/pynt-with-api-testing-tools/pynt-for-postman',
      target: 'new-tab-external-nofollow',
      text: 'Read the docs',
      type: 'tertiary'
    },
    hover: false,
    clickableCardLink: false
  }
];
export default cards;
