import React from 'react';
/* eslint-enable */

import {
  Divider,
  SectionStyles,
  BaseLink,
  SectionHeader
} from 'aether-marketing';
import CardBasic from './CardBasic';
import CardPresenterHorizontal from './CardPresenterHorizontal';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const cardBasicGrid = ({
  header,
  items,
  cta,
  fullWidthImage,
  altBoxShadow,
  divider,
  textAlign,
  cards,
  body
}) => (
  <>
    <SectionStyles>
      <div className="container">
        {/* Optional "header" above grid. */}
        {header && (
          <div className="row">
            <SectionHeader
              title={header}
              textAlign={textAlign === 'center' ? 'center' : 'left'}
              bottomMargin="30px"
              body={body}
            />
          </div>
        )}
        <div className="row justify-content-center">
          {items.map((item) => {
            // Presenter Card used on Speakers (Postman Intergalactic, Breaking Changes)
            if (item.type === 'CardPresenterHorizontal') {
              return (
                <CardPresenterHorizontal
                  key={pmUuid()}
                  col={item.col}
                  media={{
                    alt: `${item.speaker.name}, ${item.speaker.title} ${item.speaker.company}`,
                    src: item.media.src
                  }}
                  speaker={{
                    name: item.speaker.name,
                    title: item.speaker.title,
                    company: item.speaker.company,
                    linkedin: item.speaker.linkedin,
                    twitter: item.speaker.twitter,
                    website: item.speaker.website
                  }}
                />
              );
            }
            return (
              <CardBasic
                key={pmUuid()}
                col={item.colWidth}
                textPadding={item.textPadding}
                textAlign={textAlign}
                eyebrow={item.eyebrow}
                tags={item.tags}
                banner={item.banner}
                media={item.media}
                header={item.header}
                body={item.body}
                link={item.link}
                hover={item.hover}
                clickableCardLink={item.clickableCardLink}
                href={item.link && item.link.src && item.link.src}
                target={item.link && item.link.target && item.link.target}
                ratio={item.ratio}
                fullWidthImage={fullWidthImage}
                altBoxShadow={altBoxShadow}
                type={item.link.type || 'arrowLink'}
                isSecondaryButton={item.link.isSecondaryButton}
                roundedCorners={item.roundedCorners && item.roundedCorners}
                borderRadius={item.borderRadius}
                backgroundColor={item.backgroundColor}
                className="mb-4"
                customCss={cards && cards.css ? cards.css : null}
              />
            );
          })}
        </div>
        {/* An optional BaseLink centered CTA.  For example, homepages "Postman's Customer Stories" section  */}
        {cta && cta.ctaText && cta.src && (
          <div className="row mt-3">
            <div
              className={`col-12${textAlign === '' ? null : ' text-center'}`}
            >
              <BaseLink
                alternative={cta.alternative ? cta.alternative : false}
                src={cta.src}
                target={cta.target}
              >
                {cta.ctaText}
              </BaseLink>
            </div>
          </div>
        )}
      </div>
    </SectionStyles>
    {(divider === true && <Divider />) || null}
  </>
);

export default cardBasicGrid;
