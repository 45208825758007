import React from 'react';
import styled from 'styled-components';
import { Primary, Secondary, BaseLink } from 'aether-marketing';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 992px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Button = styled.button`
  ${(props) =>
    props.buttonType === 'secondary'
      ? Secondary.componentStyle.rules
      : Primary.componentStyle.rules};
  background: ${(css) =>
    css.background ? `${css.background} !important` : 'transparent'};
  border: ${(css) => (css.border ? `${css.border} !important` : 'none')};
  display: inline-block;
  color: #212121 !important;
  border-radius: 4px;
  font-weight: 600;
  line-height: 1.5;
  font-size: 14px;
  padding: 6px 12px;
  margin: 0 !important;
  transition: all 0.2s ease-in-out 0s;
  text-align: center;
  border: none;
  outline: none;
  position: relative;

  :hover,
  :active,
  :focus {
    border: 1px solid #212121 !important;
    color: #212121 !important;
    opacity: 1;
    outline: none;
    text-decoration: none;
  }

`;
function LaunchPadCTA(data) {
  const { content, css, colSize, cta } = data;

  return (
    <section
      style={{
        padding: css.section.padding,
        paddingTop: css.section.paddingTop,
        paddingBottom: css.section.paddingBottom
      }}
    >
      <div className="container">
        <div className="row">
          <div
            className={colSize || 'col-sm-12'}
            style={{
              background: css.column.background,
              borderRadius: css.column.borderRadius,
              padding: css.column.padding
            }}
          >
            <ContentWrapper className="row align-lg-middle">
              {content && (
                <div className="col-12 col-lg-11 pr-lg-0">
                  <div
                    className="mb-0"
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </div>
              )}
              <div className="col-12 col-lg-1 p-0">
                {cta && cta.type === 'button' && (
                  <div className="mt-lg-0 mt-3 ml-lg-auto">
                    <Button
                      as="a"
                      css={css.button}
                      buttonType={cta.buttonType}
                      href={cta.src}
                      target={cta.target}
                      dataTest={cta.target}
                      id={cta.id}
                    >
                      {cta.text}
                    </Button>
                  </div>
                )}
                {cta && cta.type === 'link' && (
                  <div className="">
                    <BaseLink
                      target={cta.target}
                      src={cta.src}
                      dataTest={cta.target}
                      id={cta.id}
                      linkType="arrowLink"
                    >
                      {cta.text}
                    </BaseLink>
                  </div>
                )}
              </div>
            </ContentWrapper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LaunchPadCTA;
