// Plain Header (H3) and P tag for sections

import React from 'react';
/* eslint-enable */

import styled from 'styled-components';
import {
  SectionStyles,
  Divider,
  BaseLink,
  SectionHeader
} from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

export const BlockQuoteStyles = styled.blockquote`
  border: 0;
  display: block;
  font-size: 2.4rem;
  line-height: 1.6;
  margin: 0 20px;
  position: relative;
  z-index: 10;
  @media screen and (min-width: 768px) {
    margin: 0;
  }

  div {
    position: relative;
  }

  .start-quote,
  .end-quote {
    position: absolute;
    height: fit-content;
    width: fit-content;
    color: ${(props) => props.theme.colors.orange_40};
    font-size: 4.8rem;
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1;
  }

  .start-quote {
    left: -20px;
    top: -3px;
  }

  cite p {
    font-size: 1.6rem; /* 16px */
    font-style: normal;
    margin-top: 2.4rem;
    & strong {
      font-family: ${(props) => props.theme.fonts.Inter};
      font-weight: 600;
    }
  }
`;

const BackgroundImageLeft = styled.img`
  position: absolute;
  left: ${(props) => props.data.backgroundImageLeft.position.left};
  top: ${(props) => props.data.backgroundImageLeft.position.top};

  @media screen and (max-width: 768px) {
    display: none;
    visibility: hidden;
  }
`;

const BackgroundImageRight = styled.img`
  position: absolute;
  right: ${(props) => props.data.backgroundImageRight.position.right};
  top: ${(props) => props.data.backgroundImageRight.position.top};

  @media screen and (max-width: 768px) {
    display: none;
    visibility: hidden;
  }
`;

const sectionQuote = (data) => (
  <>
    <SectionStyles backgroundColor={data.backgroundColor}>
      <div className="container">
        {data.title ? (
          <div className="row mb-3">
            <SectionHeader
              title={data.title}
              body={data.body}
              textAlign="center"
              bottomMargin="4rem"
            />
          </div>
        ) : null}
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <img
              className="col-md-12 col-lg-6 mb-4 img-fluid"
              src={data.media.src}
              backgroundSize="cover"
              height={data.media.height ? data.media.height : 'null'}
              marginBottom={
                data.media.marginBottom ? data.media.marginBottom : 0
              }
              aria-label={data.media.alt || ''}
            />
          </div>
          <div className="col-sm-12 col-md-8">
            <BlockQuoteStyles>
              <div>
                <span className="start-quote">“</span>
                <span>{data.quote}</span>
                <span className="end-quote">”</span>
              </div>
              <cite>
                <p className="mt-3">
                  {data.citationLink ? (
                    <strong>
                      <a
                        href={data.citationLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {data.citationName}
                      </a>
                    </strong>
                  ) : (
                    <strong>{data.citationName}</strong>
                  )}
                  {data.citationTitle && data.citationName
                    ? `, ${data.citationTitle}`
                    : `${data.citationTitle}`}
                </p>
              </cite>
            </BlockQuoteStyles>
            {data.link && (
              <span>
                {data.link &&
                  Array.isArray(data.link) &&
                  data.link.map((link) => (
                    <div
                      className="col-sm-12 col-md-8 offset-md-2 text-center mt-5"
                      key={pmUuid()}
                    >
                      <BaseLink
                        linkType={link.type}
                        src={link.src}
                        className={link.className && link.className}
                        alternative={
                          link.alternative ? link.alternative : false
                        }
                        target={link.target ? link.target : 'same-tab'}
                        gaCategory={link.gaCategory && link.gaCategory}
                        gaLabel={link.gaLabel && link.gaLabel}
                        id={link.id && link.id}
                        ariaLabel={link.ariaLabel && link.ariaLabel}
                      >
                        {link.text}
                      </BaseLink>
                    </div>
                  ))}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="position-relative">
        {data.backgroundImageLeft && (
          <BackgroundImageLeft
            data={data}
            src={data.backgroundImageLeft.src}
            alt={data.backgroundImageLeft.alt}
          />
        )}
        {data.backgroundImageRight && (
          <BackgroundImageRight
            data={data}
            src={data.backgroundImageRight.src}
            alt={data.backgroundImageRight.alt}
          />
        )}{' '}
      </div>
    </SectionStyles>

    {(data.divider === true && <Divider />) || null}
  </>
);

export default sectionQuote;
