/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { SectionStyles } from 'aether-marketing';
// eslint-disable-next-line import/no-extraneous-dependencies
const pmUuid = require('@postman/uuid');
// eslint-enable

const TableContainer = styled.div`
  @media (max-width: 1068px) {
    overflow-x: auto;
  }
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 300px repeat(4, 1fr);
  position: relative;
  gap: 0;
  border: 1px solid #e6e6e6;
`;

const GradientColumn = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 300px;
  height: 100%;
  background: linear-gradient(178.82deg, #fcfaff 10%, #e4d8f6 84.03%);
  z-index: 0;
  pointer-events: none;
  /* Add mask to exclude topic rows */
  mask-image: linear-gradient(
    to right,
    black calc(100% - 1px),
    transparent calc(100% - 1px)
  );
`;

const HeaderRow = styled.div`
  display: contents;
`;

const HeaderCell = styled.div`
  padding: 16px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: ${(props) => (props.isCompetitor ? 'center' : 'flex-start')};
  align-items: center;
  background: ${(props) => (props.isPostman ? 'transparent' : 'white')};
  grid-column: ${(props) => (props.isCompetitor ? '2 / -1' : 'auto')};
  border: ${(props) =>
    props.isPostman ? '1px solid #c0a8e1' : '1px solid #e6e6e6'};

  img {
    height: 50px;
    width: auto;
  }
`;

const TopicRow = styled.div`
  grid-column: 1 / -1;
  background: #f5f5f5;
  padding: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  z-index: 2; /* Higher z-index than gradient */
  p {
    font-weight: bold;
  }
  img {
    margin-right: 12px;
    width: 40px;
    height: 40px;
  }
`;

const ContentRow = styled.div`
  display: contents;
`;

const Cell = styled.div`
  padding: 20px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border: ${(props) =>
    props.isPostmanColumn ? '1px solid #c0a8e1' : '1px solid #e6e6e6'};
  min-height: 100%;
  background: ${(props) => (props.isPostmanColumn ? 'transparent' : 'white')};

  p {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .success {
    background-image: url('https://voyager.postman.com/icon/checkbox-outline-green-icon.svg');
    background-repeat: no-repeat;
    background-position: 0px 5px;
    padding-left: 24px;
  }
  .failure {
    background-image: url('https://voyager.postman.com/icon/x-icon-red.svg');
    background-repeat: no-repeat;
    background-position: 0px 5px;
    padding-left: 24px;
  }
  .partial {
    background-image: url('https://voyager.postman.com/icon/outline-ellipse-icon.svg');
    background-repeat: no-repeat;
    background-position: 0px 5px;
    padding-left: 24px;
  }
`;

const PricingHeaderCell = styled(Cell)`
  position: sticky;
  top: 54px;
  z-index: 3;
  background: ${(props) => (props.isPostmanColumn ? '#fcfaff' : 'white')};
  border-bottom: 2px solid #e6e6e6;
`;

function renderContent(content) {
  if (Array.isArray(content)) {
    return content.map((line) => (
      <div key={pmUuid()} dangerouslySetInnerHTML={{ __html: line }} />
    ));
  }
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
}

function renderCellContent(feature, competitor) {
  const content = feature[competitor.name.toLowerCase().replace(/ /g, '_')];
  return renderContent(content);
}

function ComparisonTable(props) {
  const {
    postmanLogo,
    competitorLogo,
    postmanName,
    competitorNames,
    topics,
    pricing
  } = props;

  return (
    <SectionStyles paddingBottom="60px" paddingTop="60px">
      <div className="container">
        <TableContainer>
          <Table>
            <GradientColumn />
            {/* Logo Headers */}
            <HeaderRow>
              <HeaderCell isPostman>
                <img src={postmanLogo} alt="Postman" className="mx-auto" />
              </HeaderCell>
              <HeaderCell isCompetitor>
                <img src={competitorLogo} alt="Competitor" />
              </HeaderCell>
            </HeaderRow>

            {/* Company Headers */}
            <HeaderRow className="sticky-pricing">
              <PricingHeaderCell isPostmanColumn>
                <h4 className="h5 text-center mb-2">{postmanName}</h4>
                {pricing && pricing[0] && (
                  <p className="text-center">{pricing[0].postman}</p>
                )}
              </PricingHeaderCell>
              {competitorNames.map((competitor) => (
                <PricingHeaderCell key={pmUuid()}>
                  {competitor.href ? (
                    <h4 className="h5 text-center mb-2">
                      <a href={competitor.href}>{competitor.name}</a>
                    </h4>
                  ) : (
                    <h4 className="h5 text-center mb-2">{competitor.name}</h4>
                  )}
                  {pricing && pricing[0] && (
                    <p className="text-center">
                      {
                        pricing[0][
                          competitor.name.toLowerCase().replace(/ /g, '_')
                        ]
                      }
                    </p>
                  )}
                </PricingHeaderCell>
              ))}
            </HeaderRow>
            {/* Topics and Features */}
            {topics.map((topic) => (
              <React.Fragment key={pmUuid()}>
                <TopicRow>
                  {topic.icon && <img src={topic.icon} alt={topic.name} />}
                  <h5 className="h6 mb-0">{topic.name}</h5>
                </TopicRow>
                {topic.features.map((feature) => (
                  <ContentRow key={pmUuid()}>
                    <Cell isPostmanColumn>
                      {renderContent(feature.postman)}
                    </Cell>
                    {competitorNames.map((competitor) => (
                      <Cell key={pmUuid()}>
                        {renderCellContent(feature, competitor)}
                      </Cell>
                    ))}
                  </ContentRow>
                ))}
              </React.Fragment>
            ))}
          </Table>
        </TableContainer>
      </div>
    </SectionStyles>
  );
}

export default ComparisonTable;
