import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import PropTypes from 'prop-types';
import {
  Divider,
  SectionStyles,
  BaseLinkStyles,
  BaseLink,
  UnorderedListStyles,
  OrderedListStyles,
  BaseButton,
  SectionHeader
} from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const ContentContainer = styled.div`
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }

  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }

  ol {
    ${OrderedListStyles.componentStyle.rules}
  }
`;

const EmbedContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
  z-index: 0; /* Safari rounded corner bug hack */

  &.primary-border-thin {
    border-radius: 10px;
    border: 1px solid rgb(230, 230, 230);
  }
  &.fullwrap {
    border-radius: ${(props) => props.theme.borderRadius.large};
    border: 4px solid #e6e6e6;
    -webkit-box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
    box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
  }
  &.top-bottom {
    border-top: 4px solid #e6e6e6;
    border-bottom: 4px solid #e6e6e6;
    -webkit-box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
    box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
    max-width: 1600px;
    margin: auto;
    display: block;
    @media screen and (min-width: 1600px) {
      border: 4px solid #e6e6e6;
      border-radius: ${(props) => props.theme.borderRadius.large};
    }
  }
  &.embed-responsive video {
    height: auto !important;
  }
`;

function SideBySideMediaTextV6Section({
  backgroundColor,
  body,
  className,
  ctaButton,
  ctaLinks,
  modal,
  divider,
  title,
  textAlign,
  videoEmbed,
  isGif,
  videoType,
  videoId,
  poster,
  vttFile,
  paddingTop,
  paddingBottom,
  logo,
  fullWidth,
  autoPlay,
  muted,
  loop,
  controls,
  reverseOrder
}) {
  const textSection = (
    <div
      className={`col-sm-12 col-md-10 offset-md-1 col-lg-4 ${
        reverseOrder
          ? 'order-lg-1 offset-lg-1'
          : 'order-lg-0 offset-lg-0 mb-lg-0 mb-4'
      } d-flex align-items-center`}
    >
      <div className="text-left">
        <ContentContainer>
          {logo ? (
            <img className="w-100 mb-4" src={logo.src} alt={logo.alt} />
          ) : null}
          {body && <div dangerouslySetInnerHTML={{ __html: body.join('') }} />}
        </ContentContainer>
        {ctaLinks &&
          Array.isArray(ctaLinks) &&
          ctaLinks.map((cta) => (
            <p key={pmUuid()}>
              <BaseLink
                linkType={cta.type}
                src={cta.src}
                className={cta.className && cta.className}
                alternative={cta.alternative ? cta.alternative : false}
                target={cta.target ? cta.target : 'same-tab'}
                gaCategory={cta.gaCategory && cta.gaCategory}
                gaLabel={cta.gaLabel && cta.gaLabel}
                id={cta.id && cta.id}
                ariaLabel={cta.ariaLabel && cta.ariaLabel}
              >
                {cta.text}
              </BaseLink>
            </p>
          ))}
        {ctaButton &&
          Array.isArray(ctaButton) &&
          ctaButton.map((cta) => (
            <div className="mb-4 mr-3 d-inline" key={pmUuid()}>
              <BaseButton
                buttonType={cta.buttonType}
                as="a"
                src={cta.src}
                target={cta.target}
                id={cta.id}
                gaCategory={cta.gaCategory}
                gaLabel={cta.gaLabel}
                className={cta.type === 'inline' ? 'pl-0' : null}
                modal={modal || null}
                icon={cta.icon}
                dataTest={cta.id}
              >
                {cta.text}
              </BaseButton>
            </div>
          ))}
      </div>
    </div>
  );
  /* eslint-disable jsx-a11y/media-has-caption */
  const mediaSection = (
    <div
      className={`col-12 mb-lg-0 col-lg-7 ${
        reverseOrder
          ? 'order-lg-0 offset-lg-0 mb-lg-0 mb-4'
          : 'order-lg-1 offset-lg-1'
      } order-0 d-flex align-items-center justify-content-center`}
    >
      <EmbedContainer
        className={`embed-responsive embed-responsive-16by9 ${className}`}
      >
        <video
          id={videoId}
          preload="metadata"
          src={videoEmbed}
          type={videoType}
          poster={poster}
          autoPlay={autoPlay}
          muted={muted}
          loop={loop}
          controls={controls}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          playsInline={isGif && isGif}
        >
          <track
            label="English"
            kind="subtitles"
            srcLang="en"
            src={vttFile}
            default
          />
          Sorry, your browser doesn&apos;t support embedded videos.
        </video>
      </EmbedContainer>
    </div>
  );
  /* eslint-enable jsx-a11y/media-has-caption */
  return (
    <>
      <SectionStyles
        className="mb-0"
        backgroundColor={backgroundColor}
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
      >
        <div className="container">
          {title ? (
            <div className="row mb-5">
              <SectionHeader
                title={title}
                body={body}
                textAlign={textAlign}
                bottomMargin="0"
              />
            </div>
          ) : null}
          <div className="row">
            {reverseOrder ? (
              <>
                {mediaSection}
                {textSection}
              </>
            ) : (
              <>
                {textSection}
                {mediaSection}
              </>
            )}
          </div>
        </div>
      </SectionStyles>

      {(divider === true && <Divider fullWidth={fullWidth} />) || null}
    </>
  );
}

const sideBySideMediaTextV6 = (data) => {
  const {
    backgroundColor,
    body,
    className,
    ctaButton,
    ctaLinks,
    modal,
    divider,
    fullWidth,
    title,
    textAlign,
    videoEmbed,
    videoType,
    videoId,
    vttFile,
    poster,
    paddingTop,
    paddingBottom,
    logo,
    autoPlay,
    muted,
    loop,
    controls,
    reverseOrder,
    isGif
  } = data;

  return (
    <SideBySideMediaTextV6Section
      backgroundColor={backgroundColor}
      body={body}
      ctaButton={ctaButton || null}
      ctaLinks={ctaLinks || null}
      className={className}
      modal={modal}
      divider={divider}
      fullWidth={fullWidth}
      title={title}
      textAlign={textAlign}
      videoEmbed={videoEmbed}
      videoType={videoType}
      videoId={videoId}
      vttFile={vttFile}
      poster={poster}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      logo={logo}
      autoPlay={autoPlay}
      muted={muted}
      loop={loop}
      controls={controls}
      reverseOrder={reverseOrder || false}
      isGif={isGif}
    />
  );
};

SideBySideMediaTextV6Section.propTypes = {
  backgroundColor: PropTypes.string,
  paddingTop: PropTypes.string,
  className: PropTypes.string,
  paddingBottom: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  fullWidth: PropTypes.bool,
  ctaButton: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        src: PropTypes.string,
        target: PropTypes.string,
        id: PropTypes.string,
        gaCategory: PropTypes.string,
        gaLabel: PropTypes.string,
        icon: PropTypes.string
      })
    ),
    PropTypes.oneOf([null])
  ]),
  ctaLinks: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        src: PropTypes.string,
        target: PropTypes.string,
        id: PropTypes.string,
        gaCategory: PropTypes.string,
        gaLabel: PropTypes.string,
        icon: PropTypes.string
      })
    ),
    PropTypes.oneOf([null])
  ]),
  modal: PropTypes.shape({
    type: PropTypes.string,
    src: PropTypes.string
  }),
  divider: PropTypes.bool,
  videoEmbed: PropTypes.string.isRequired,
  videoType: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  vttFile: PropTypes.string.isRequired,
  isGif: PropTypes.bool,
  poster: PropTypes.string,
  title: PropTypes.string,
  textAlign: PropTypes.string,
  logo: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }),
  autoPlay: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  controls: PropTypes.bool,
  reverseOrder: PropTypes.bool
};

SideBySideMediaTextV6Section.defaultProps = {
  backgroundColor: null,
  paddingTop: null,
  paddingBottom: null,
  body: null,
  ctaButton: {
    id: null,
    gaCategory: null,
    gaLabel: null,
    icon: null
  },
  ctaLinks: {
    id: null,
    gaCategory: null,
    gaLabel: null,
    icon: null
  },
  modal: null,
  divider: false,
  title: null,
  textAlign: null,
  logo: null,
  poster: null,
  fullWidth: false,
  className: '',
  autoPlay: false,
  muted: false,
  loop: false,
  controls: false,
  reverseOrder: false,
  isGif: false
};

export default sideBySideMediaTextV6;
