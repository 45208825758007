/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
// eslint-disable-next-line react/jsx-key
import React from 'react';
import styled from 'styled-components';
import { SectionStyles } from 'aether-marketing';
// eslint-disable-next-line import/no-extraneous-dependencies
const pmUuid = require('@postman/uuid');
// eslint-enable
const TableContainer = styled.div`
  @media (max-width: 1068px) {
    overflow-x: auto;
  }
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 40vw 70vw 70vw;
  position: relative;
  gap: 1px;
  border: 1px solid #e6e6e6;
  &:nth-child(1) {
    border-left: none;
    border-top: none;
  }

  @media (min-width: 1068px) {
    grid-template-columns: ${(props) =>
      props.columnWidths.map((width) => width).join(' ')};
  }
`;

const GradientColumn = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10vw;
  width: 100vw;
  @media (min-width: 1068px) {
    left: 300px;
    width: 440px;
  }
  background: ${(props) => props.gradient};
  z-index: 0;
`;

const Cell = styled.div`
  padding: 10px;
  @media (min-width: 1068px) {
    padding: ${(props) => {
      return props.paddingCell ? props.paddingCell : '28px 20px 30px 22px;';
    }};
  }
  background: ${(props) => {
    if (props.highlight) return 'transparent';
    if (props.rowBackground) return props.rowBackground;
    return 'white';
  }};
  display: flex;
  justify-content: ${(props) => (props.isHeader ? 'center' : 'flex-start')};
  min-height: 100%;
  position: relative;
  z-index: 1;
  border-left: ${(props) => (props.highlight ? '1px solid #C0A8E1' : '')};
  border-right: ${(props) =>
    props.highlight ? '1px solid #C0A8E1' : '1px solid #e6e6e6'};

  border-top: ${(props) =>
    props.highlight ? '1px solid #C0A8E1' : '1px solid #e6e6e6'};


  &:nth-child(1) {
    border-left: ${(props) => (props.rowBackground || !props.isHeader ? '1px solid #e6e6e6' : '')};
    border-bottom: none;
  }

  ${(props) =>
    props.isHeader &&
    `
        &:first-of-type {
          border-top: none;
        }
      `}
  &:nth-child(3) {
    border-right: 1px solid #e6e6e6 !important;
  }
  .success {
    background-image: url('https://voyager.postman.com/icon/checkbox-outline-green-icon.svg');
    background-repeat: no-repeat;
    background-position: 0px 5px;
  }
  .failure {
    background-image: url('https://voyager.postman.com/icon/x-icon-red.svg');
    background-repeat: no-repeat;
    background-position: 0px 5px;
  }

  .partial {
    background-image: url('https://voyager.postman.com/icon/outline-ellipse-icon.svg');
    background-repeat: no-repeat;
    background-position: 0px 5px;
  }
`;

const HeaderImage = styled.img`
  height: 50px;
  width: auto;
  @media (min-width: 1068px) {
    height: ${(props) => (props.imgHeight ? props.imgHeight : '60px')};
  }
`;

const Row = styled.div`
  display: contents;

  .row-image {
    width: 40px;
    height: 40px;
  }
`;

function CustomTable({
  data,
  imgHeight,
  columns,
  columnWidths = ['300px', '440px', '440px'],
  highlightColumn,
  highlightColor,
  headerImages = {},
  rowBackgrounds = [] // Default value for rowBackgrounds
}) {
  return (
    <SectionStyles paddingBottom="60px" paddingTop="60px">
      <div className="container">
        <TableContainer>
          <Table columns={columns} columnWidths={columnWidths}>
            {highlightColumn !== null && (
              <GradientColumn
                columnIndex={highlightColumn}
                totalColumns={columns.length}
                gradient={highlightColor}
              />
            )}
            <Row>
              {columns.map((col) => (
                <Cell
                  key={pmUuid()}
                  isHeader
                  highlight={col === columns[highlightColumn]}
                >
                  {headerImages[col] ? (
                    <HeaderImage
                      src={headerImages[col]}
                      alt={col}
                      width="0"
                      imgHeight={imgHeight || '60px'}
                    />
                  ) : (
                    ''
                  )}
                </Cell>
              ))}
            </Row>
            {data.map((row, rowIndex) => {
              return (
                <Row key={pmUuid()}>
                  {columns.map((col) => {
                    const htmlString = row[col].join('');
                    return (
                      <Cell
                        key={pmUuid()}
                        highlight={col === columns[highlightColumn]}
                        rowBackground={rowBackgrounds[rowIndex]}
                        paddingCell={row.paddingCell ? row.paddingCell : ''}
                      >
                        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
                      </Cell>
                    );
                  })}
                </Row>
              );
            })}
          </Table>
        </TableContainer>
      </div>
    </SectionStyles>
  );
}

export default CustomTable;
