import React from 'react';
import styled from 'styled-components';
import { Divider, BaseButton } from 'aether-marketing';
// import LottiAnimationContainer from './LottiAnimationContainer';
import SingleInputForm from './forms/layout/SingleInputForm';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  background: #14071c;
`;

const BgImageContainer = styled.div`
  max-width: 2060px;
  background: #14071c;
  margin-top: -2vw;
  .img-wide {
    display: none;
  }
  .img-crowd {
    display: block;
  }
  @media (min-width: 2000px) {
    margin-top: -3vh;
    .img-wide {
      display: block;
    }
    .img-crowd {
      display: none;
    }
  }
`;

const HeroWrapper = styled.section`
  margin-top: -15vw;
  @media (min-width: 2601px) {
    margin-top: -12vw;
  }
`;

const HeroContent = styled.div`
  color: white;
  position: relative;

  // .img-container {
  //   padding-left: 10.5px;
  // }
  span {
    color: white;
  }

  h2 {
    font-size: 50px;
  }

  @media (max-width: 992px) {
    margin-left: 0 !important;
    text-align: center;
    h2 {
      font-size: 45px;
    }
    img {
      margin-left: 0;
    }
  }

  .h3,
  h3 {
    font-size: 36px;
    font-weight: 600;
    line-height: 1.2;
  }
  .white {
    color: white;
  }
  .gradientText,
  h2 {
    background-image: linear-gradient(to right, #ff6c37, #c64dda);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
  }
`;

// const LottieWrapper = styled.div`
//   width: 90%;
//   overflow: hidden;
//   .animation-container {
//     position: relative;
//     top: 50px;
//   }
//   svg {
//     display: block;
//     position: relative;
//     object-fit: contain;
//     overflow-x: hidden;
//   }
//   .overlay {
//     overflow-x: hidden;
//     display: block;
//     position: absolute;
//     top: 65%;
//     right: 9%;
//     width: 500px;
//     height: 190px;
//     background: linear-gradient(
//       to bottom,
//       rgba(255, 255, 255, 0),
//       rgba(20, 7, 28, 0.7) 19%,
//       rgba(20, 7, 28, 1) 38%,
//       rgba(20, 7, 28, 0.17)
//     );
//   }
// `;
// const TitleImage = styled.img`
//   width: 100%;

// `;
const ContentWrapper = styled.div`
  display: flex;
  align-items: center;

  margin-top: 20px;

  .text-orange {
    color: #ff6c37;
    font-weight: bold;
  }
`;
const SvgContainer = styled.div`
  svg {
    height: auto;
  }
`;

/* eslint-disable react/prop-types */
function PostconHero({
  title,
  subtitle,
  button,
  divider,
  backgroundImage,
  form
}) {
  return (
    <Wrapper className="pb-4">
      <BgImageContainer className="mx-auto">
        <img
          src={backgroundImage.src}
          alt={backgroundImage.alt}
          className="img-fluid img-crowd"
        />
        <img
          src="https://voyager.postman.com/post-con/postcon-24-post-event-hero-wide.jpg"
          alt="Postcon Hero Crowd"
          className="img-fluid img-wide"
        />
      </BgImageContainer>
      <HeroWrapper id="postcon-2025">
        <div className="container">
          <HeroContent className="">
            <div className="img-container ">
              <SvgContainer>
                <img
                  src="https://voyager.postman.com/post-con/2025/postcon-25-logo-animation.svg"
                  alt="Postcon 25 Logo with animation of 4 sliding down out of view and 5 sliding down into view"
                  eager="true"
                  className="img-fluid img-crowd"
                />
              </SvgContainer>
            </div>

            <ContentWrapper className="col-lg-8 order-0 order-lg-0 pl-0">
              {title && <h2 className="in-view">{title}</h2>}
              {subtitle && (
                <div dangerouslySetInnerHTML={{ __html: subtitle.join('') }} />
              )}
              {button && (
                <BaseButton
                  buttonType={button.buttonType}
                  as="a"
                  src={button.src}
                  target={button.target}
                  id={button.id}
                  gaCategory={button.gaCategory}
                  gaLabel={button.gaLabel}
                  dataTest={button.id}
                  className="mt-4"
                >
                  {button.text}
                </BaseButton>
              )}

              {form && (
                <div className="px-0 col-12 col-md-8 col-lg-12 mx-auto mb-1">
                  <SingleInputForm form={form} />
                </div>
              )}
            </ContentWrapper>
            {/* {lottieMedia && (
              <div className="col-lg-6">
                <LottieWrapper className="d-none d-lg-block m-auto">
                  <LottiAnimationContainer
                    isLottie={lottieMedia.isLottie}
                    autoplay={lottieMedia.autoplay}
                    loop={lottieMedia.loop}
                    animationFileName={lottieMedia.lottieFileName}
                  />
                  <div className="overlay" />
                </LottieWrapper>
              </div>
            )} */}
          </HeroContent>
        </div>
        {divider && <Divider />}
      </HeroWrapper>
    </Wrapper>
  );
}
/* eslint-enable react/prop-types */
export default PostconHero;
