import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Divider,
  SectionStyles,
  Paragraph,
  BaseButton,
  BaseLink
} from 'aether-marketing';

import '../../../styles/config/_pm-icons.css';
import manageDeviceId from '../../../utils/manageDeviceId';
import ScreenshotContainer from './screenshotContainer';

const BoxSection = styled.div`
  -webkit-filter: drop-shadow(${(props) => props.theme.shadows.card_shadow});
  filter: drop-shadow(${(props) => props.theme.shadows.card_shadow});
  border-radius: ${(props) => props.theme.borderRadius.medium};

  .line {
    margin: 32px 0;
  }
`;

/* GA Click Tracking */
const handleArtemis = () => {
  return (
    window.pmt &&
    window.pmt('ga', [
      'send',
      'event',
      'Downloads-page',
      'Click',
      'sign-up-for-a-postman-account'
    ])
  );
};

const handleLinkClickReleaseNotes = () => {
  return (
    window.pmt &&
    window.pmt('ga', [
      'send',
      'event',
      'Downloads-page',
      'Click',
      'release-notes'
    ])
  );
};

/* GA Tracking for Orange download buttons and Not your OS */
/* Mac */
const handleDownloadMacIntelChip = () => {
  return (
    window.pmt &&
    window.pmt('ga', [
      'send',
      'event',
      'Downloads-page',
      'Click',
      'mac-intel-download-the-app'
    ])
  );
};

const handleDownloadMacAppleChip = () => {
  return (
    window.pmt &&
    window.pmt('ga', [
      'send',
      'event',
      'Downloads-page',
      'Click',
      'mac-apple-download-the-app'
    ])
  );
};

const downloadNotOSMacIntelChip = () => {
  return (
    window.pmt &&
    window.pmt('ga', [
      'send',
      'event',
      'Downloads-page',
      'Click',
      'not-your-os-mac-intel-chip'
    ])
  );
};

const downloadNotOSMacAppleChip = () => {
  return (
    window.pmt &&
    window.pmt('ga', [
      'send',
      'event',
      'Downloads-page',
      'Click',
      'not-your-os-mac-apple-chip'
    ])
  );
};

const handleDownloadWin64 = () => {
  return (
    window.pmt &&
    window.pmt('ga', [
      'send',
      'event',
      'Downloads-page',
      'Click',
      'win-64-download-the-app'
    ])
  );
};

const downloadNotOSWindow64 = () => {
  return (
    window.pmt &&
    window.pmt('ga', [
      'send',
      'event',
      'Downloads-page',
      'Click',
      'not-your-os-win-64'
    ])
  );
};
/* Linux GA */
const handleDownloadLinux64 = () => {
  return (
    window.pmt &&
    window.pmt('ga', [
      'send',
      'event',
      'Downloads-page',
      'Click',
      'linux-x64-download-the-app'
    ])
  );
};
const handleDownloadLinuxArm64 = () => {
  return (
    window.pmt &&
    window.pmt('ga', [
      'send',
      'event',
      'Downloads-page',
      'Click',
      'linux-arm-64-download-the-app'
    ])
  );
};

const downloadNotOSLinux64 = () => {
  return (
    window.pmt &&
    window.pmt('ga', [
      'send',
      'event',
      'Downloads-page',
      'Click',
      'not-your-os-linux-x64'
    ])
  );
};
const downloadNotOSLinuxArm64 = () => {
  return (
    window.pmt &&
    window.pmt('ga', [
      'send',
      'event',
      'Downloads-page',
      'Click',
      'not-your-os-linux-arm-64'
    ])
  );
};

// Checks if a user is signed in or logged out
const getCookie = (a) => {
  if (typeof document !== 'undefined') {
    const b = document.cookie.match(`(^|;)\\s*${a}\\s*=\\s*([^;]+)`);
    return b ? b.pop() : '';
  }
  return false;
};
// Functional Component to display "Try the Web Version" or "Launch Postman" buttons
function SwitchWebOrPostmanHomeLink({ data }) {
  const { cookie, beta, hidden } = data;
  if (!hidden) {
    const src =
      (cookie !== 'yes' &&
        `https://identity.getpostman${beta}.com/signup?continue=https%3A%2F%2Fgo.postman${beta}.co%2Fhome`) ||
      `https://go.postman${beta}.co/home`;
    const ctaText =
      (cookie !== 'yes' && 'Try the Web Version') || 'Launch Postman';
    const eventProp = `{"cta_link_to":"${src}","cta_style":"primary","cta_text":"${ctaText}","cta_type":"button"}`;
    return (
      <BaseButton
        buttonType="secondary"
        target="new-tab-postman"
        as="a"
        src={manageDeviceId(src)}
        className="w-100 text-center"
        onClick={handleArtemis}
        event="downloadsPageTryWebClicked"
        eventProp={eventProp}
        dataTest={
          cookie !== 'yes'
            ? 'downloads-create-account-postman-on-the-web'
            : 'downloads-launch-postman-on-the-web'
        }
      >
        {' '}
        {ctaText}
      </BaseButton>
    );
  }
  return null;
}

function getDownloadButtonEventData({ src, ctaText, ctaStyle, ctaType }) {
  const style = ctaStyle || 'primary';
  const type = ctaType || 'button';
  return `{"cta_link_to":"${src}","cta_style":"${style}","cta_text":"${ctaText}","cta_type":"${type}"}`;
}

class DownloadsHeroContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      platform: 'mac',
      cookie: '',
      beta: '',
      hidden: true
    };
  }

  componentDidMount() {
    // Check cookie to toggle "Try the Web Version" button HREF
    const cookie = getCookie('getpostmanlogin');
    const beta = window.location.host.includes('postman-beta') ? '-beta' : '';
    this.setState({
      cookie,
      beta
    });
    /* setTimeout is used to display the correct button after the cookie is checked */
    /* eslint-disable react/prop-types */
    const { waitBeforeShow } = this.props;
    /* eslint-enable react/prop-types */
    setTimeout(() => {
      this.setState({ hidden: false });
    }, waitBeforeShow);

    /* Sets the platform the user is on */
    this.setState({ platform: navigator.platform.toLowerCase() });

    window.addEventListener('click', (e) => {
      const env =
        (!!document.location.host.match('localhost') && 'DEV') ||
        (!!document.location.host.match('beta') && 'BETA') ||
        (!!document.location.host.match('stage') && 'STAGE') ||
        (!!document.location.host.match('preview') && 'PREV') ||
        (!!document.location.host.match('postman.com') && 'PROD');

      if (
        e.target.getAttribute('data-event') ===
        'downloadsPageDownloadButtonClicked'
      ) {
        const postDownloadURL = `https://pst-dl.getpostman${
          ((env === 'DEV' || env === 'BETA') && '-beta') ||
          (env === 'STAGE' && '-stage') ||
          (env === 'PREV' && '-preview') ||
          ''
        }.com/app/download/start`;

        window.pmt('trace', [postDownloadURL, 'newWindow']);
      }
    });
  }

  render() {
    const { platform, cookie, beta, hidden } = this.state;
    /* Not your OS download links that display below orange buttons */
    let NotYourOs;
    /* Mac */
    if (platform.includes('mac')) {
      NotYourOs = (
        <small className="footnote">
          Not your OS?
          {` Download for Windows (`}
          <a
            href="https://dl.pstmn.io/download/latest/win64"
            onClick={downloadNotOSWindow64}
            aria-label="Download Postman App for Windows 64-bit"
            data-test="not-os-win64-link"
            data-event="downloadsPageDownloadButtonClicked"
            data-event-prop={getDownloadButtonEventData({
              src: 'https://dl.pstmn.io/download/latest/win64',
              ctaText: 'Not OS Windows 64-bit',
              ctaType: 'link'
            })}
          >
            x64
          </a>
          &#41; or Linux &#40;
          <a
            href="https://dl.pstmn.io/download/latest/linux_64"
            onClick={downloadNotOSLinux64}
            aria-label="Not your OS? Download Postman App for Linux x64"
            data-test="not-os-linux-x64-link"
            data-event="downloadsPageDownloadButtonClicked"
            data-event-prop={getDownloadButtonEventData({
              src: 'https://dl.pstmn.io/download/latest/linux_64',
              ctaText: 'Not OS Linux 64-bit',
              ctaType: 'link'
            })}
          >
            x64
          </a>
          {`, `}
          <a
            href="https://dl.pstmn.io/download/latest/linux_arm64"
            onClick={downloadNotOSLinuxArm64}
            aria-label="Not your OS? Download Postman App for Linux arm64"
            data-test="not-os-linux-arm64-link"
            data-event="downloadsPageDownloadButtonClicked"
            data-event-prop={getDownloadButtonEventData({
              src: 'https://dl.pstmn.io/download/latest/linux_arm64',
              ctaText: 'Not OS Linux arm64',
              ctaType: 'link'
            })}
          >
            arm64
          </a>
          &#41;
        </small>
      );
      /* Windows */
    } else if (platform.includes('win')) {
      NotYourOs = (
        <small>
          Not your OS?
          {` Download for Mac (`}
          <a
            href="https://dl.pstmn.io/download/latest/osx_64"
            onClick={downloadNotOSMacIntelChip}
            aria-label="Not your OS? Download Postman App for Mac Intel Chip"
            data-test="not-os-mac-intel-link"
            data-event="downloadsPageDownloadButtonClicked"
            data-event-prop={getDownloadButtonEventData({
              src: 'https://dl.pstmn.io/download/latest/osx_64',
              ctaText: 'Not OS Mac Intel Chip',
              ctaType: 'link'
            })}
          >
            Intel Chip
          </a>
          {`, `}
          <a
            href="https://dl.pstmn.io/download/latest/osx_arm64"
            onClick={downloadNotOSMacAppleChip}
            aria-label="Not your OS? Download Postman App for Mac Apple Chip"
            data-test="not-os-mac-apple-link"
            data-event="downloadsPageDownloadButtonClicked"
            data-event-prop={getDownloadButtonEventData({
              src: 'https://dl.pstmn.io/download/latest/osx_arm64',
              ctaText: 'Not OS Mac Apple Chip',
              ctaType: 'link'
            })}
          >
            Apple Chip
          </a>
          &#41; or Linux &#40;
          <a
            href="https://dl.pstmn.io/download/latest/linux_64"
            onClick={downloadNotOSLinux64}
            aria-label="Not your OS? Download Postman App for Linux x64"
            data-test="not-os-linux-x64-link"
            data-event="downloadsPageDownloadButtonClicked"
            data-event-prop={getDownloadButtonEventData({
              src: 'https://dl.pstmn.io/download/latest/linux_64',
              ctaText: 'Not OS Linux 64-bit',
              ctaType: 'link'
            })}
          >
            x64
          </a>
          {`, `}
          <a
            href="https://dl.pstmn.io/download/latest/linux_arm64"
            onClick={downloadNotOSLinuxArm64}
            aria-label="Not your OS? Download Postman App for Linux arm64"
            data-test="not-os-linux-arm64-link"
            data-event="downloadsPageDownloadButtonClicked"
            data-event-prop={getDownloadButtonEventData({
              src: 'https://dl.pstmn.io/download/latest/linux_arm64',
              ctaText: 'Not OS Linux arm64',
              ctaType: 'link'
            })}
          >
            arm64
          </a>
          &#41;
        </small>
      );
      /* Linux */
    } else if (platform.includes('linux')) {
      NotYourOs = (
        <small>
          Not your OS?
          {` Download for Windows (`}
          <a
            href="https://dl.pstmn.io/download/latest/win64"
            onClick={downloadNotOSWindow64}
            aria-label="Not your OS? Download Postman App for Windows 64-bit"
            data-test="not-os-win64-link"
            data-event="downloadsPageDownloadButtonClicked"
            data-event-prop={getDownloadButtonEventData({
              src: 'https://dl.pstmn.io/download/latest/win64',
              ctaText: 'Not OS Windows 64-bit',
              ctaType: 'link'
            })}
          >
            x64
          </a>
          &#41; or Mac &#40;
          <a
            href="https://dl.pstmn.io/download/latest/osx_64"
            onClick={downloadNotOSMacIntelChip}
            aria-label="Not your OS? Download Postman App for Mac Intel Chip"
            data-test="not-os-mac-intel-link"
            data-event="downloadsPageDownloadButtonClicked"
            data-event-prop={getDownloadButtonEventData({
              src: 'https://dl.pstmn.io/download/latest/osx_64',
              ctaText: 'Not OS Mac Intel Chip',
              ctaType: 'link'
            })}
          >
            Intel Chip
          </a>
          {`, `}
          <a
            href="https://dl.pstmn.io/download/latest/osx_arm64"
            onClick={downloadNotOSMacAppleChip}
            aria-label="Not your OS? Download Postman App for Mac Apple Chip"
            data-test="not-os-mac-apple-link"
            data-event="downloadsPageDownloadButtonClicked"
            data-event-prop={getDownloadButtonEventData({
              src: 'https://dl.pstmn.io/download/latest/osx_arm64',
              ctaText: 'Not OS Mac Apple Chip',
              ctaType: 'link'
            })}
          >
            Apple Chip
          </a>
          &#41;
        </small>
      );
    } else {
      NotYourOs = (
        <small>
          Not your OS?
          {` Download for Windows (`}
          <a
            href="https://dl.pstmn.io/download/latest/win64"
            onClick={downloadNotOSWindow64}
            aria-label="Not your OS? Download Postman App for Windows 64-bit"
            data-test="not-os-win64-link"
            data-event="downloadsPageDownloadButtonClicked"
            data-event-prop={getDownloadButtonEventData({
              src: 'https://dl.pstmn.io/download/latest/win64',
              ctaText: 'Not OS Windows 64-bit',
              ctaType: 'link'
            })}
          >
            x64
          </a>
          &#41; or Linux &#40;
          <a
            href="https://dl.pstmn.io/download/latest/linux_64"
            onClick={downloadNotOSLinux64}
            aria-label="Not your OS? Download Postman App for Linux x64"
            data-test="not-os-linux-x64-link"
            data-event="downloadsPageDownloadButtonClicked"
            data-event-prop={getDownloadButtonEventData({
              src: 'https://dl.pstmn.io/download/latest/linux_64',
              ctaText: 'Not OS Linux 64-bit',
              ctaType: 'link'
            })}
          >
            x64
          </a>
          {`, `}
          <a
            href="https://dl.pstmn.io/download/latest/linux_arm64"
            onClick={downloadNotOSLinuxArm64}
            aria-label="Not your OS? Download Postman App for Linux arm64"
            data-test="not-os-linux-arm64-link"
            data-event="downloadsPageDownloadButtonClicked"
            data-event-prop={getDownloadButtonEventData({
              src: 'https://dl.pstmn.io/download/latest/linux_arm64',
              ctaText: 'Not OS Linux arm64',
              ctaType: 'link'
            })}
          >
            arm64
          </a>
          &#41;
        </small>
      );
    }
    /* Downloads Buttons per OS / Function determines which OS to download */
    let downloadButton;
    if (!hidden) {
      if (platform.includes('mac')) {
        downloadButton = (
          <div className="row">
            <div className="col-6">
            <BaseButton
                buttonType="primary"
                as="a"
                src="https://dl.pstmn.io/download/latest/osx_arm64"
                className="mx-auto w-100 mb-4 text-center"
                waitBeforeShow={200}
                onClick={handleDownloadMacAppleChip}
                dataTest="download-the-app-mac-apple-chip"
                id="download-the-app-mac-apple-chip"
                aria-label="Download Postman App for Mac Apple Chip"
                style={{ whiteSpace: 'nowrap' }}
                event="downloadsPageDownloadButtonClicked"
                eventProp={getDownloadButtonEventData({
                  src: 'https://dl.pstmn.io/download/latest/osx_arm64',
                  ctaText: 'Mac Apple Chip'
                })}
              >
                <i className="pm-icon-apple pr-2 w-100" />
                Mac Apple Chip
              </BaseButton>
            </div>
            <div className="col-6">
            <BaseButton
                buttonType="primary"
                as="a"
                src="https://dl.pstmn.io/download/latest/osx_64"
                className="mx-auto w-100 mb-4 text-center"
                waitBeforeShow={200}
                onClick={handleDownloadMacIntelChip}
                dataTest="download-the-app-mac-intel-chip"
                id="download-the-app-mac-intel-chip"
                aria-label="Download Postman App for Mac Intel Chip"
                style={{ whiteSpace: 'nowrap' }}
                event="downloadsPageDownloadButtonClicked"
                eventProp={getDownloadButtonEventData({
                  src: 'https://dl.pstmn.io/download/latest/osx_64',
                  ctaText: 'Mac Intel Chip'
                })}
              >
                <i className="pm-icon-apple pr-2 w-100" />
                Mac Intel Chip
              </BaseButton>
            </div>
          </div>
        );
      } else if (platform.includes('win')) {
        downloadButton = (
          <div className="d-flex">
            <BaseButton
              buttonType="primary"
              as="a"
              src="https://dl.pstmn.io/download/latest/win64"
              className="mx-auto w-100 mb-4"
              waitBeforeShow={200}
              onClick={handleDownloadWin64}
              dataTest="download-the-app-windows-64"
              id="download-the-app-windows-64"
              aria-label="Download Postman App for Windows 64-bit"
              style={{ whiteSpace: 'nowrap' }}
              event="downloadsPageDownloadButtonClicked"
              eventProp={getDownloadButtonEventData({
                src: 'https://dl.pstmn.io/download/latest/win64',
                ctaText: 'Windows 64-bit'
              })}
            >
              <i className="pm-icon-windows pr-2 w-100" />
              Windows 64-bit
            </BaseButton>
          </div>
        );
      } else if (platform.includes('linux')) {
        downloadButton = (
          <div className="row">
            <div className="col-6">
              <BaseButton
                buttonType="primary"
                as="a"
                src="https://dl.pstmn.io/download/latest/linux_64"
                className="mx-auto w-100 mb-4 text-center"
                waitBeforeShow={200}
                onClick={handleDownloadLinux64}
                dataTest="download-the-app-linux-x64"
                id="download-the-app-linux-x64"
                aria-label="Download Postman App for Linux x64"
                style={{ whiteSpace: 'nowrap' }}
                event="downloadsPageDownloadButtonClicked"
                eventProp={getDownloadButtonEventData({
                  src: 'https://dl.pstmn.io/download/latest/linux_64',
                  ctaText: 'Linux x64'
                })}
              >
                <i className="pm-icon-linux pr-2 w-100" />
                Linux (x64)
              </BaseButton>
            </div>
            <div className="col-6">
              <BaseButton
                buttonType="primary"
                as="a"
                src="https://dl.pstmn.io/download/latest/linux_arm64"
                className="mx-auto w-100 mb-4 text-center"
                waitBeforeShow={200}
                onClick={handleDownloadLinuxArm64}
                dataTest="download-the-app-linux-arm64"
                id="download-the-app-linux-arm64"
                aria-label="Download Postman App for Linux arm64"
                style={{ whiteSpace: 'nowrap' }}
                event="downloadsPageDownloadButtonClicked"
                eventProp={getDownloadButtonEventData({
                  src: 'https://dl.pstmn.io/download/latest/linux_arm64',
                  ctaText: 'Linux arm64'
                })}
              >
                <i className="pm-icon-linux pr-2 w-100" />
                Linux (arm64)
              </BaseButton>
            </div>
          </div>
        );
      }
    }

    return (
      <>
        <SectionStyles style={{ overflow: 'hidden' }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-10 offset-lg-1 text-center mb-5">
                <h1 className="mb-4">Download Postman</h1>
                <Paragraph className="subtitle">
                  Download the app to get started using the Postman API Platform
                  today. Or, if you prefer a browser experience, you can try the
                  web version of Postman.
                </Paragraph>
              </div>
            </div>
          </div>
          <div className="container mb-3">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-5 align-self-center pr-lg-5">
                    <BoxSection>
                      <h2 className="h3">The Postman app</h2>
                      <Paragraph>
                        Download the app to get started with the Postman API
                        Platform.
                      </Paragraph>
                      <div className="mb-3 d-none d-sm-block">
                        {downloadButton}
                        <small className="footnote mt-3 d-block">
                          By downloading and using Postman, I agree to the&nbsp;
                          <a
                            href="/legal/privacy-policy/"
                            data-test="downloads-privacy-policy"
                          >
                            Privacy Policy
                          </a>
                          {` and `}
                          <a href="/legal/terms/" data-test="downloads-eula">
                            Terms
                          </a>
                          .
                        </small>
                      </div>
                      <div className="d-block d-sm-none">
                        <BaseButton
                          className="mx-auto w-100 mb-4"
                          style={{
                            backgroundColor: 'grey',
                            cursor: 'not-allowed',
                            borderColor: 'transparent'
                          }}
                        >
                          Download the App
                        </BaseButton>
                        <small>
                          <strong>
                            To experience the best the Postman app has to offer,
                            please download it on your desktop.
                          </strong>
                        </small>
                      </div>
                      <div className="row d-flex flex-row align-baseline">
                        <div className="col-12">
                          <small className="footnote d-inline-flex">
                            <a
                              href="/release-notes/postman-app/"
                              data-test="downloads-release-notes"
                              onClick={handleLinkClickReleaseNotes}
                            >
                              Release Notes →
                            </a>
                            &nbsp;
                          </small>
                        </div>
                      </div>
                      <p className="small d-none d-sm-block">{NotYourOs}</p>
                      <div className="line">
                        <Divider fullWidth />
                      </div>
                      <h3>Postman on the web</h3>
                      <Paragraph>
                        Access the Postman API Platform through your web
                        browser. Create a free account, and you&apos;re in.
                      </Paragraph>
                      <SwitchWebOrPostmanHomeLink
                        waitBeforeShow={200}
                        data={{ cookie, beta, hidden }}
                      />
                      <div className="line">
                        <Divider fullWidth />
                      </div>
                      <h3>Postman Enterprise</h3>
                      <Paragraph>
                        Postman Enterprise is designed for organizations who
                        need to deploy Postman at scale.
                      </Paragraph>
                      <BaseLink
                        linkType="arrowLink"
                        as="a"
                        src="/postman-enterprise/"
                      >
                        Learn more
                      </BaseLink>
                    </BoxSection>
                  </div>
                  <div
                    className="col-12 col-md-6 col-lg-7 align-self-center"
                    style={{ zIndex: '1' }}
                  >
                    <ScreenshotContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SectionStyles>
        <div className="container">
          <Divider fullWidth />
        </div>
      </>
    );
  }
}

SwitchWebOrPostmanHomeLink.propTypes = {
  data: PropTypes.shape({
    cookie: PropTypes.string.isRequired,
    beta: PropTypes.string.isRequired,
    hidden: PropTypes.bool
  }).isRequired
};

const downloadsHero = (data) => (
  <div>
    <DownloadsHeroContainer data={data} />
  </div>
);

export default downloadsHero;
